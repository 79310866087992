import React, { Component } from 'react';

class Check extends Component {
    constructor(){
        super();
        this.state = {
            label: '',
            active: false
        }
    }

    onClickBox=()=>{
        var this0 = this;
        if(this.state.active){
            this.setState({active:false}, function(){
                this0.props.onChange(this0.state.active);
            });
        }else{
            this.setState({active:true}, function(){
                this0.props.onChange(this0.state.active);
            });
        }
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({
            active: props.active || false
        });
    }

    componentDidMount(){
        let label = (this.props.label)?<div className="solocheckbox-label">{this.props.label}</div>:'';
        this.setState({label: label});
    }

    render(){
        return (
            <div onClick={this.onClickBox} className={"solocheckbox "+this.state.active+" "+this.props.className}>
                <div className="solocheckbox-box">
                    <div className={"solocheckbox-box-conte "+this.state.active}></div>
                </div>
                {this.state.label}
                {this.props.children}
            </div>
        )
    }
}

export default Check;