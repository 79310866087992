window.hntcStore = {};
window.hntcStoreList = {};

var setStore = function(node, data){
    window.hntcStore[node] = data;
    
    if(window.hntcStoreList[node] !== undefined){
        let object = window.hntcStoreList[node];
        
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const element = object[key];
                if(element !== undefined) element();
            }
        }
    }
}

var getStore = function(node){
    if(window.hntcStore[node] !== undefined)
        return window.hntcStore[node];
    else
        return {};
}

var regListener = function(node, idreg, handler){
    if(window.hntcStoreList[node] === undefined)window.hntcStoreList[node] = {};
    window.hntcStoreList[node][idreg] = handler;
}

var unregListener = function(node, idreg){
    if(window.hntcStoreList[node] !== undefined)
        if(window.hntcStoreList[node][idreg] !== undefined)
            window.hntcStoreList[node][idreg] = undefined;
}

let Store = {
    setStore: setStore,
    getStore: getStore,
    regListener: regListener,
    unregListener: unregListener
}

export default Store;