import React, { Component } from 'react';
import Loading from '../controls/loading.jsx';
import jQuery from 'jquery';

class Password extends Component {
  constructor(){
    super();
    this.state = {
      pass1: '',
      pass2: '',
      pass3: '',
      error: '',
      disableBt: 'disable',
      popwindow: ''
    }
  }

  onChangePass1=(e)=>{
    this.setState({pass1: e.target.value}, function(){
      // No validamos el primer pass
      // this0.validateForm(); 
    });
  }
  
  onChangePass2=(e)=>{
    var this0 = this;
    this.setState({pass2: e.target.value}, function(){
      this0.validateForm();
    });
  }

  validateForm=()=>{
    let pass2 = this.state.pass2;

    if(pass2.trim().length > 5){
      this.setState({disableBt: ''});
    }else{
      this.setState({disableBt: 'disable'});
    }

  }

  onSubmitPass=()=>{
    if(this.state.disableBt) return false;

    var this0 = this;

    let pass1 = this.state.pass1;
    let pass2 = this.state.pass2;

    var ajax = jQuery.post('https://app.hontrack.com/apiphp/account.php', {md:'updatepass', pass1:pass1, pass2:pass2}, function(data){
      this0.setState({popwindow:''});
      if(data === "ok"){
        this0.props.success();
      }else if(data === 'wrongpass'){
        this0.props.passnomatch();
      }else if(data === 'notlogin'){
        this0.props.history.push('/');
      }else{
        alert('error');
      }
    });

    this.setState({popwindow: <Loading close={()=>{this.setState({popwindow:''} )}} request={ajax} />});

  }

  componentDidMount(){
    this.inputPass1Ref.focus();
  }

  render() {
    return (
      <div className="form">
        {this.state.popwindow}
        <div className="form-g">
            <label>Contraseña actual</label>
            <input ref={(input)=>{this.inputPass1Ref = input;}} autoComplete={false} onChange={this.onChangePass1} name="xxpass1" type="text" />
        </div>
        <div className="form-g">
            <label>Contraseña nueva</label>
            <input onChange={this.onChangePass2} autoComplete={false} name="xxpass2" type="text" />
        </div>
        <div onClick={this.onSubmitPass} className={"form-button "+this.state.disableBt}>Cambiar</div>
      </div>
    );
  }
}

export default Password;
