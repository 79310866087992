import React, { Component } from 'react';

class Password extends Component {
  constructor(){
    super();
    this.state = {
      tel: '',
      esValido: false
    }
  }

  onChangeTel=(e)=>{
    var this0 = this;
    this.setState({tel: e.target.value}, function(){
      this0.validateForm();
      if(typeof this0.props.onChange === 'function') this0.props.onChange(this0.state);
    });
    
  }

  validateForm=()=>{
    let tel = this.state.tel;

    var regex = /^(\+?)[0-9]{8,15}$/g;
    if( regex.test(tel) ) {
      this.setState({esValido: true});
    } else {
      this.setState({esValido: false});
    }

  }

  componentDidMount(){
    this.inputPhoneRef.focus();
  }

  render() {
    return (
      <div className="form">
        <div className="form-g">
            <label>Nuevo teléfono</label>
            <input ref={(input)=>{ this.inputPhoneRef = input; }} autoComplete={false} onChange={this.onChangeTel} name="h4rgvbg35tg3b" type="text" />
        </div>
      </div>
    );
  }
}

export default Password;
