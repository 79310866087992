import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import { Link, useParams } from 'react-router-dom';
import Menu from '../controls/menu.jsx';
import jQuery from 'jquery';
import Store from '../store';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class Alerts extends Component {
    constructor(props){
        super();
        this.state = {
            id: '',
            devDataActual: [],
            configsLoaded: false,
            ajaxLoading: false,
            alertOn: false,
            alertOff: false,
            alertMove: false,
            alertSpeed: false,
            alertSpeed_SliderValue: 100,
            alertSpeed2: false,
            alertSpeed2_SliderValue: 100,
            alertSpeed3: false,
            alertSpeed3_SliderValue: 100,
            alertSOS: false,
            alertFRENON: false,
            alertACELERON: false,
            popwindow: false,
            ajax1: ''
        }
    }

    componentDidMount(){
        Store.regListener("ui", 'alertsconfig',this.onuiStoreChange);
        Store.regListener("devicesAll", 'alertsconfig',this.onDevicesAllStoreChange);
        Store.regListener("isLoggin", 'alertsconfig',this.onisLogginChange);
        this.setState({ui: Store.getStore('ui'), id:this.props.params.id}, ()=>{
            this.cargarConfig();
            this.onDevicesAllStoreChange();
        });
    }

    componentWillReceiveProps(props){
        this.setState({id:props.params.id});
    }

    onuiStoreChange=()=>{
        this.setState({ui: Store.getStore('ui')});
    }

    onDevicesAllStoreChange=()=>{
		// Cuando la informacion de los dispositivos es actualizada
		var id = this.props.params.id;
		var devicesAll = Store.getStore('devicesAll');
		for (const key in devicesAll) {
			if (devicesAll.hasOwnProperty(key)) {
				const element = devicesAll[key];
				if(element.DevCod === id){
					this.setState({devDataActual: element});
				}
			}
		}
    }

    onisLogginChange=()=>{
        if(!Store.getStore('isLoggin')){
            this.props.history.push('/');
        }
	}

    componentWillUnmount(){
        if(this.state.ajax1) this.state.ajax1.abort();
        Store.unregListener("ui", 'alertsconfig');
        Store.unregListener("devicesAll", 'alertsconfig');
        Store.unregListener("isLoggin", 'alertsconfig');
    }

    onChange_alertOn=(value)=>{
        // Activar o desactivar alerta de encendido
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertOn: !this.state.alertOn, ajaxLoading: true});
        if(this.state.alertOn){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARON", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARON", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertOff=(value)=>{
        // Activar o desactivar alerta de encendido
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertOff: !this.state.alertOff, ajaxLoading: true});
        if(this.state.alertOff){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "AROFF", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "AROFF", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertMove=(value)=>{
        // Activar o desactivar alerta de movimiento
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertMove: !this.state.alertMove, ajaxLoading: true});
        if(this.state.alertMove){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARMOV", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARMOV", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertSpeed=(value)=>{
        // Activar o desactivar alerta de velocidad
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertSpeed: !this.state.alertSpeed, ajaxLoading: true});
        if(this.state.alertSpeed){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertSpeed2=(value)=>{
        // Activar o desactivar alerta de velocidad
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertSpeed2: !this.state.alertSpeed2, ajaxLoading: true});
        if(this.state.alertSpeed2){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD2", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD2", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertSpeed3=(value)=>{
        // Activar o desactivar alerta de velocidad
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertSpeed3: !this.state.alertSpeed3, ajaxLoading: true});
        if(this.state.alertSpeed3){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD3", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSPD3", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertSOS=(value)=>{
        // Activar o desactivar alerta de SOS
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertSOS: !this.state.alertSOS, ajaxLoading: true});
        if(this.state.alertSOS){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSOS", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSOS", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertACELERON=(value)=>{
        // Activar o desactivar alerta de SOS
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertACELERON: !this.state.alertACELERON, ajaxLoading: true});
        if(this.state.alertACELERON){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARACELERON", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARACELERON", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    onChange_alertFRENON=(value)=>{
        // Activar o desactivar alerta de SOS
        if(this.state.ajaxLoading){ alert('Espere'); return false; }
        
        let id = this.state.id;
        this.setState({alertFRENON: !this.state.alertFRENON, ajaxLoading: true});
        if(this.state.alertFRENON){
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARFRENON", val: "NO"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }else{
            jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARFRENON", val: "SI"}, (data)=>{
                this.setState({ajaxLoading: false});
            }); 
        }
    }

    alertSpeed_changeSlider=(v)=>{
        this.setState({alertSpeed_SliderValue: v});
    }

    alertSpeed_AfterChangeSlider=()=>{
        if(this.state.ajaxLoading){ alert('Espere'); return false; }

        let id = this.state.id;
        this.setState({ajaxLoading: true});
        jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSLI", val: this.state.alertSpeed_SliderValue}, (data)=>{
            this.setState({ajaxLoading: false});
        }); 
    }

    alertSpeed2_changeSlider=(v)=>{
        this.setState({alertSpeed2_SliderValue: v});
    }

    alertSpeed2_AfterChangeSlider=()=>{
        if(this.state.ajaxLoading){ alert('Espere'); return false; }

        let id = this.state.id;
        this.setState({ajaxLoading: true});
        jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSLI2", val: this.state.alertSpeed2_SliderValue}, (data)=>{
            this.setState({ajaxLoading: false});
        }); 
    }

    alertSpeed3_changeSlider=(v)=>{
        this.setState({alertSpeed3_SliderValue: v});
    }

    alertSpeed3_AfterChangeSlider=()=>{
        if(this.state.ajaxLoading){ alert('Espere'); return false; }

        let id = this.state.id;
        this.setState({ajaxLoading: true});
        jQuery.post('https://app.hontrack.com/apiphp/config.php', {md: "set", dev: id, nom: "ARSLI3", val: this.state.alertSpeed3_SliderValue}, (data)=>{
            this.setState({ajaxLoading: false});
        }); 
    }

    cargarConfig=()=>{

        this.setState({ajaxLoading: true});
        jQuery.post('https://app.hontrack.com/apiphp/config.php', {md:'getDev', dev:this.state.id}, (data)=>{
            this.setState({ajaxLoading: false});
            console.log(data);
            if(typeof data === 'object'){
                this.setState({configsLoaded: true});
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        let element = data[key];
                        if(element.CnfNom === 'ARON' && element.CnfVal === 'SI'){
                            this.setState({alertOn: true});
                        }
                        if(element.CnfNom === 'AROFF' && element.CnfVal === 'SI'){
                            this.setState({alertOff: true});
                        }
                        if(element.CnfNom === 'ARMOV' && element.CnfVal === 'SI'){
                            this.setState({alertMove: true});
                        }
                        if(element.CnfNom === 'ARSPD' && element.CnfVal === 'SI'){
                            this.setState({alertSpeed: true});
                        }
                        if(element.CnfNom === 'ARSPD2' && element.CnfVal === 'SI'){
                            this.setState({alertSpeed2: true});
                        }
                        if(element.CnfNom === 'ARSPD3' && element.CnfVal === 'SI'){
                            this.setState({alertSpeed3: true});
                        }
                        if(element.CnfNom === 'ARSOS' && element.CnfVal === 'SI'){
                            this.setState({alertSOS: true});
                        }
                        if(element.CnfNom === 'ARACELERON' && element.CnfVal === 'SI'){
                            this.setState({alertACELERON: true});
                        }
                        if(element.CnfNom === 'ARFRENON' && element.CnfVal === 'SI'){
                            this.setState({alertFRENON: true});
                        }
                        if(element.CnfNom === 'ARSLI'){
                            this.setState({alertSpeed_SliderValue: parseInt(element.CnfVal, 10)});
                        }
                        if(element.CnfNom === 'ARSLI2'){
                            this.setState({alertSpeed2_SliderValue: parseInt(element.CnfVal, 10)});
                        }
                        if(element.CnfNom === 'ARSLI3'){
                            this.setState({alertSpeed3_SliderValue: parseInt(element.CnfVal, 10)});
                        }
                    }
                }
            }else{
                this.setState({configsLoaded: true});
                this.setState({alertOn: false});
                this.setState({alertOff: false});
                this.setState({alertMove: false});
                this.setState({alertSpeed: false});
                this.setState({alertSOS: false});
                this.setState({alertACELERON: false});
                this.setState({alertFRENON: false});
                this.setState({alertSpeed_SliderValue: 100});
                this.setState({alertSpeed2_SliderValue: 100});
                this.setState({alertSpeed3_SliderValue: 100});
            }
        });
    }

    render() {
        var genSwitchsContent = ()=>{
            return (
                <div className="genSwitchsContent">
                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de encendido</h1>
                            <span>Recibir una alerta cuando tu vehículo sea encendido</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertOn} type="checkbox" checked={this.state.alertOn} className="switch"/>
                        </div>
                    </div>
                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de apagado</h1>
                            <span>Recibir una alerta cuando el vehículo sea apagado</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertOff} type="checkbox" checked={this.state.alertOff} className="switch"/>
                        </div>
                    </div>
                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de movimiento</h1>
                            <span>Recibir una alerta cuando tu vehículo es movido de su ubicación</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertMove} type="checkbox" checked={this.state.alertMove} className="switch"/>
                        </div>
                    </div>
                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Frenado brusco</h1>
                            <span>Recibir una alerta cuando el vehículo es detenido o frenado bruscamente.</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertFRENON} type="checkbox" checked={this.state.alertFRENON} className="switch"/>
                        </div>
                    </div>
                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Acelerón brusco</h1>
                            <span>Recibir una alerta cuando el vehículo es acelerado bruscamente.</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertACELERON} type="checkbox" checked={this.state.alertACELERON} className="switch"/>
                        </div>
                    </div>



                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de velocidad en ciudad</h1>
                            <span>Recibir una alerta cuando tu vehiculo exceda los <b>{this.state.alertSpeed_SliderValue}km/h</b></span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertSpeed} type="checkbox" checked={this.state.alertSpeed} className="switch"/>
                        </div>
                        <div style={{'paddingTop':'2em'}}>
                            <Slider 
                                onAfterChange={this.alertSpeed_AfterChangeSlider} 
                                onChange={this.alertSpeed_changeSlider} 
                                step={10}
                                min={20}
                                value={this.state.alertSpeed_SliderValue}
                                max={250} 
                                />
                        </div>
                    </div>

                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de velocidad en autopista</h1>
                            <span>Recibir una alerta cuando tu vehiculo exceda los <b>{this.state.alertSpeed2_SliderValue}km/h</b></span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertSpeed2} type="checkbox" checked={this.state.alertSpeed2} className="switch"/>
                        </div>
                        <div style={{'paddingTop':'2em'}}>
                            <Slider 
                                onAfterChange={this.alertSpeed2_AfterChangeSlider} 
                                onChange={this.alertSpeed2_changeSlider} 
                                step={10}
                                min={20}
                                value={this.state.alertSpeed2_SliderValue}
                                max={250} 
                                />
                        </div>
                    </div>

                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta de velocidad en zona rural</h1>
                            <span>Recibir una alerta cuando tu vehiculo exceda los <b>{this.state.alertSpeed3_SliderValue}km/h</b></span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertSpeed3} type="checkbox" checked={this.state.alertSpeed3} className="switch"/>
                        </div>
                        <div style={{'paddingTop':'2em'}}>
                            <Slider 
                                onAfterChange={this.alertSpeed3_AfterChangeSlider} 
                                onChange={this.alertSpeed3_changeSlider} 
                                step={10}
                                min={20}
                                value={this.state.alertSpeed3_SliderValue}
                                max={250} 
                                />
                        </div>
                    </div>


                    <div className="genSwitchsContent-ele">
                        <div className="genSwitchsContent-ele-text">
                            <h1>Alerta SOS</h1>
                            <span>Recibir una alerta cuando se presiona el boton de panico</span>
                        </div>
                        <div className="genSwitchsContent-ele-switch">
                            <input onClick={this.onChange_alertSOS} type="checkbox" checked={this.state.alertSOS} className="switch"/>
                        </div>
                    </div>
                </div>
            )
        }

        return (
        <div className="app">
            
            {this.state.popwindow}

            <div className="appbar">
                <Link to={"/dashboard/"+this.state.id} className="appbar-title">Configurar Alertas</Link>
                <Link to={"/dashboard/"+this.state.id} className="appbar-b1"><img alt="" src={Icons['back']} /></Link>
                <Link to={"/dashboard/"+this.state.id} className="appbar-b2"><img alt="" src={Icons['logo']} /></Link>
            </div>

            <div className={this.state.ui === "desktop" ? "appcon uidesktop soloizq" : "appcon"}>
                {this.state.ui === "desktop" && <Menu id1={this.state.id} />}
                <div className="appcon-scroll100">
                    <div className="appcon-scroll100-conte">
                        <center>
                            <div className="appcon-header">
                                <h1 className="titulopegado">{this.state.devDataActual.DevDesc}</h1>
                                <span>Alertas para este vehículo.</span>
                            </div>
                        </center>
                        {this.state.configsLoaded && genSwitchsContent()}
                        {this.state.configsLoaded || <center><br></br>Cargando...</center>}
                    </div>
                </div>
            </div>
        </div>
        );
    }
}


let AlertsHook = () => {
	let params = useParams();
	return <Alerts params = {params}  />;
} 

export default AlertsHook;

