import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import { NavLink } from 'react-router-dom';
import Store from '../store';

// Filtros todo, block, mov, on
class Menuseldev extends Component {
	constructor(){
		super();
		this.state = {
			data: [],
			compsList: [],
			linktipo: 't1',
			tipoFiltro: "todo",
			visibleBarraBuscar: false,
			buscarInputValue: ''
		};
		this.REF_inputBuscar = null;
	}

	UNSAFE_componentWillMount(){
		Store.regListener("devicesAll", 'menuseldev',this.onDevicesAllStoreChange);
	}

	componentDidMount(){
		this.onDevicesAllStoreChange();
	}
	
	componentWillUnmount(){
		Store.unregListener("devicesAll", 'menuseldev');
	}

	onDevicesAllStoreChange=()=>{
		this.hacerAlInicio();
	}

	hacerAlInicio=()=>{
		var devDataList = Store.getStore('devicesAll');
		if(!devDataList) return false;
		var compsList = [];
		for (const key in devDataList) {
			if (devDataList.hasOwnProperty(key)) {
				let element = devDataList[key];
				let urlto = "/dashboard/"+element.DevCod;
				let stateEngine = '';
				if(this.props.linktipo === "t2") urlto = "/vehicle/"+element.DevCod;
				if(this.props.linktipo === "t3") urlto = "/historymap/"+element.DevIMEI+"/"+element.DevCod;
				if(this.props.linktipo === "vehicle") urlto = "/vehicle/"+element.DevCod;
				if(element.DevACC === 1) stateEngine = ' active';
				
				// eslint-disable-next-line
				var pushRow = ()=>{
					compsList.push(
						<NavLink key={element.DevCod} activeClassName="active" className="iconlink" to={urlto} >
							<div className="iconlink-svg">
								<div className={"iconlink-svg-state"+stateEngine}></div>
								<img alt="" src={Icons['carb']} />
							</div>
							<div className="iconlink-text">
								<b>{element.DevDesc}</b>
								<span>{element.DevLstSpd > 1 ? "En movimiento" : "Detenido"}</span>
							</div>
						</NavLink>
					)
				}
				if(this.state.visibleBarraBuscar){
					let exp = new RegExp(this.state.buscarInputValue, "i");
					if(element.DevDesc.match(exp)) pushRow();
				}else{
					if(this.state.tipoFiltro === "on" && element.DevACC === 1) pushRow();
					if(this.state.tipoFiltro === "off" && element.DevACC === 0) pushRow();
					if(this.state.tipoFiltro === "mov" && element.DevLstSpd > 1) pushRow();
					if(this.state.tipoFiltro === "block" && element.DevBlk === "ON") pushRow();
					if(this.state.tipoFiltro === "todo") pushRow();
				}

			}
		}
		if(!compsList.length){
			compsList = <div className="menuseldev-list-nada">No hay vehículos que mostrar.</div>;
		}
		this.setState({compsList: compsList});
	}

	ocultarBuscar=()=>{
		this.setState({visibleBarraBuscar:false, buscarInputValue: ''},()=>{
			this.hacerAlInicio();
		});
	}

	mostrarBuscar=()=>{
		this.setState({visibleBarraBuscar:true},()=>{
			this.REF_inputBuscar.focus();
			this.hacerAlInicio();
		});
	}

	onChangeBuscarInput=(event)=>{
		this.setState({buscarInputValue: event.target.value},()=>{
			this.hacerAlInicio();
		});
	}

	renderBotonesFiltro=()=>{
		//let bk0 = {};
		let bk1 = {};
		let bk2 = {};
		let bk3 = {};
		let bk4 = {};

		//if(this.state.tipoFiltro === "off") bk0 = {background: "#e62a2a"};
		if(this.state.tipoFiltro === "on") bk1 = {background: "#e62a2a"};
		if(this.state.tipoFiltro === "block") bk2 = {background: "#e62a2a"};
		if(this.state.tipoFiltro === "mov") bk3 = {background: "#e62a2a"};
		if(this.state.tipoFiltro === "sea")	bk4 = {background: "#e62a2a"};

		var cambiarFiltro = (v)=>{
			if(v === this.state.tipoFiltro){
				this.setState({tipoFiltro: "todo"},()=>{
					this.hacerAlInicio();
				});
			}else{
				this.setState({tipoFiltro: v},()=>{
					this.hacerAlInicio();
				});
			}
		}

		return (
			<div className="menuseldev-header-btns">
				<div title="Mostrar solo vehículos encendidos" onClick={()=>{cambiarFiltro("on")}} style={bk1} className="btn tipo1"><img src={Icons["carb"]} alt=""/></div>
				<div title="Mostrar solo vehículos en movimiento" onClick={()=>{cambiarFiltro("mov")}} style={bk3} className="btn"><img src={Icons["speedb"]} alt=""/></div>
				<div title="Mostrar solo vehículos bloqueados" onClick={()=>{cambiarFiltro("block")}} style={bk2} className="btn"><img src={Icons["apagado1"]} alt=""/></div>
				<div title="Buscar vehículo" onClick={()=>{this.mostrarBuscar()}} style={bk4} className="btn"><img src={Icons["search"]} alt=""/></div>
			</div>
		)
	}

	render() {
		return (
			<div className={"menuseldev "+this.props.className}>
				<div className="menuseldev-header">
					{this.state.visibleBarraBuscar && 
						<div className="menuseldev-header-buscar">
							<div onClick={this.ocultarBuscar} className="menuseldev-header-buscar-cerrar"><img src={Icons["close"]} alt=""/></div>
							<input className="menuseldev-header-buscar-input" ref={elem => (this.REF_inputBuscar = elem)} value={this.state.buscarInputValue} onChange={this.onChangeBuscarInput} placeholder="Buscar vehículo" type="text"/>
						</div>
					}
					<span>Filtrar:</span>
					{this.renderBotonesFiltro()}
				</div>
				<div className="menuseldev-list">
					{this.state.compsList}
				</div>
			</div>
		);
	}
}

export default Menuseldev;
