import React, { Component } from 'react';
import Icons from '../controls/svgicons';

class Offline extends Component {
	constructor(){
		super();
		this.state = {
		};
	}

    clickRecargar=()=>{
        window.location.reload();
    }

	render() {
		return (
            <div className="offlineview">
                <div className="offlineview-logo">
                    <img src={Icons['logob']} alt=""/>
                </div>
                <div className="offlineview-text">No tienes internet en este momento, intentalo de nuevo en unos minutos.</div>
                <div onClick={this.clickRecargar} className="offlineview-button">Recargar</div>
            </div>
		);
	}
}

export default Offline;
