import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import { Link } from 'react-router-dom';

class NoLogin extends Component {
	constructor(){
		super();
		this.state = {
		};
	}

	render() {
		return (
            <div className="nologinview">
                <div className="nologinview-logo">
                    <img src={Icons['logob']} alt=""/>
                </div>
                <div className="nologinview-text">Debes tener acceso para ver esta pantalla.</div>
                <Link to="/">Volver al login</Link>
            </div>
		);
	}
}

export default NoLogin;
