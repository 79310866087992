import React, { Component } from 'react';

class Dropmenu extends Component {
	constructor(){
		super();
		this.state = {
			menuDataComp: '',
			menustate:false
		};
	}

	componentWillReceiveProps(props){
		this.setState({menustate:props.showstate});

		let menuDataComp = [];
		for (var key in this.props.menuData) {
			if (this.props.menuData.hasOwnProperty(key)) {
				var element = this.props.menuData[key];
				let img = (element.img)?<img src={element.img} alt=""/>:<div className="noimg" />;
				let title = (element.title)?<div>{element.title}</div>:"";
				let subtitle = (element.subtitle)?<span>{element.subtitle}</span>:"";
				menuDataComp.push(
					<div className="dropmenu-pop-ele" key={key} onClick={element.function} >
						{img}
						<div className="dropmenu-pop-ele-con">
							{title}
							{subtitle}
						</div>
					</div>
				);
			}
		}
		this.setState({menuDataComp:menuDataComp});
	}

	render() {
		return (
			<div onClick={this.props.closeMenu} className={this.props.className+ " dropmenu "+this.state.menustate}>
				<div className="dropmenu-pop">
					{this.state.menuDataComp}
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default Dropmenu;
