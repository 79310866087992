import React, { Component } from 'react';

class Alert extends Component{

    close = () => {
        if(typeof this.props.closefn === 'function') this.props.closefn();
    }

    clickwindow = (e) => {
        e.stopPropagation();
    }

    render(){
        return(
            <div onClick={this.close} className="cont-popwindow">
                <div onClick={this.clickwindow} className="popwindow">
                    <div className="popwindow-cont">
                        {this.props.children}
                    </div>
                    <div className="popwindow-botbar">
                        <button onClick={this.close}>Aceptar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Alert;