import React, { Component } from 'react';

class Password extends Component {
  constructor(){
    super();
    this.state = {
      name: '',
      esValido: false
    }
  }

  onChangeName=(e)=>{
    var this0 = this;
    this.setState({name: e.target.value}, function(){
      this0.validateForm();
      if(typeof this0.props.onChange === 'function') this0.props.onChange(this0.state);
    });
  }

  validateForm=()=>{
    let name = this.state.name;
    if(name.trim().length > 8){
      this.setState({esValido:true});
    }else{
      this.setState({esValido: false});
    }
  }

  componentDidMount(){
    this.textInput.focus();
  }


  render() {
    return (
      <div className="form">
        <div className="form-g">
            <label>Nuevo nombre</label>
            <input ref={(input)=>{ this.textInput = input; }} onChange={this.onChangeName} value={this.props.valueF} name="v34tsdv43sv" type="text" />
        </div>
      </div>
    );
  }
}

export default Password;
