import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../controls/svgicons';
import Alert from '../controls/alert.jsx';
import jQuery from 'jquery';

class Recovery extends Component {
  constructor(){
    super();
    this.state = {
      usuario: '',
      popwindow: '',
      ajax1: ''
    }
  }

  enviarDatos=()=>{
    var aceptarboton = () => { this.setState({popwindow:''}); }
    var this0 = this;
    let validateMail = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/gm;
    if(this.state.usuario.match(validateMail)){
      var ajax1 = jQuery.post("https://app.hontrack.com/apiphp/account.php", {md:'recovery', mail:this.state.usuario},function(data){
        if(data === 'ok'){
          let alert = (
            <Alert closefn={aceptarboton}>
              <img className="bigicon" src={Icons['done']} alt=""/>
              <h3>Se te enviará un correo con información para recuperar tu cuenta.</h3>
            </Alert>
          );
          this0.setState({popwindow:alert});
        }else{
          let alert = (
            <Alert closefn={aceptarboton}>
              <img className="bigicon" src={Icons['error']} alt=""/>
              <h3>No se encontro ese usuario</h3>
            </Alert>
          );
          this0.setState({popwindow:alert, usuario:''});
        }
      });
      this.setState({ajax1:ajax1});
    }else{
      let alert = (
        <Alert closefn={aceptarboton}>
          <img className="bigicon" src={Icons['error']} alt=""/>
          <h3>Ingrese su correo correctamente.</h3>
        </Alert>
      );
      this.setState({popwindow:alert});
    }
  }

  onChangeUser=(e)=>{
    this.setState({usuario:e.target.value});
  }

  componentWillUnmount(){
    if(this.state.ajax1) this.state.ajax1.abort();
  }

  render() {
    return (
      <div className="app">
        {this.state.popwindow}
        <div className="pddapp">
            <Link className="pddapp-logo" to="/"><img alt="" src={Icons['logo']} /></Link>
            <div className="form">
                <div className="form-title">Recuperar cuenta</div>
                <div className="form-g">
                    <label>Ingresa tu correo</label>
                    <input onChange={this.onChangeUser} value={this.state.usuario} type="text" />
                </div>
                
                <button onClick={this.enviarDatos} className="form-button">Recuperar</button>
                <Link className="form-button2" to="/">Volver</Link>
            </div>
        </div>
      </div>
    );
  }
}

export default Recovery;
