import React, { Component } from 'react';

class Loading extends Component{
    constructor(){
        super();
        this.state = {
            btCancel: '',
            timeOut1: '',
            timeOut2: ''
        };
    }

    cancelarAjax=()=>{
        if(this.props.request) this.props.request.abort();
        if(this.props.close) this.props.close();
    }

    componentDidMount(){

        var this0 = this;
        var timeOut1 = false;
        var timeOut2 = false;

        if(this.props.tipo === "texto"){ // Si solo queremos mostrar texto en una area en partircular

            this0.setState({popwindow: "Cargando..."});
            
            timeOut1 = setTimeout(function(){ // Tiempo en el cual aparecera el boton cargando.
                this0.setState({popwindow: "Espere por favor..."});
            }, 4000);
    
            timeOut2 = setTimeout(function(){ // Tiempo en el cual se cancelara todo.
                this0.setState({popwindow: "Conexión perdida."});
                this0.cancelarAjax();
            }, 20000);

        }else{

            this0.setState({
                popwindow:(
                    <div className="cont-popwindow">
                        <div className="popwindowloading">
                            <div className="loader"></div>
                        </div>
                    </div>
                )
            });
    
            timeOut1 = setTimeout(function(){ // Tiempo en el cual aparecera el boton cargando.
                this0.setState({popwindow:(
                    <div className="cont-popwindow">
                        <div className="popwindowloading">
                            <div className="loader"></div>
                            <button onClick={this0.cancelarAjax}>Cancelar</button>
                        </div>
                    </div>
                )});
            }, 3000);
    
            timeOut2 = setTimeout(function(){ // Tiempo en el cual se cancelara todo.
                this0.cancelarAjax();
            }, 15000);
        }
        
        this0.setState({timeOut1:timeOut1, timeOut2:timeOut2});
    }

    componentWillUnmount(){
        clearTimeout(this.state.timeOut1);
        clearTimeout(this.state.timeOut2);
    }

    render(){
        return(
            <div>
                {this.state.popwindow}
            </div>
        );
    }
}

export default Loading;