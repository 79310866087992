import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../controls/svgicons';
import Alert from '../controls/alert.jsx';
import Loading from '../controls/loading.jsx';
import Check from '../controls/check.jsx';
import jQuery from 'jquery';
import Store from '../store';

class Login extends Component {
	constructor(){
		super();
        this.state = {
            identificacion:'',
            clave: '',
            tempop: '',
            readOnlyPass: true,
            checkActiveState: false
        }
	}

    componentDidMount(){
        if(window.localStorage.getItem('hontrackSavePassClient')){
            this.setState({
                checkActiveState: true,
                identificacion:window.localStorage.getItem('hontrackSavePassClient_usrid')||"", 
                clave: window.localStorage.getItem('hontrackSavePassClient_userpass')||""});
            }else{
                this.setState({checkActiveState: false});
       }

       Store.regListener("ui", 'login',this.onuiStoreChange);
       Store.regListener("devicesAll", 'login',this.onDevicesAllStoreChange);
       Store.regListener("isLoggin", 'login',this.onDevicesAllStoreChange);
       
     
    }

    onuiStoreChange=()=>{
        console.log('onuiStoreChange');
    }
    
    onDevicesAllStoreChange=()=>{
        console.log('onDevicesAllStoreChange');
    }
    
    onisLogginChange=()=>{
        // console.log('onisLogginChange');
        if(Store.getStore('isLoggin')){
            this.props.history.push('/dashboard');
        }
    }
    
    cargarDispositivos = (callback) =>{
        // Hacer peticion para recuperar todos los dispositivos gps ligados al usuario
        // esto tambien esta en env.jsx si se cambia cambiar alla tambien
		jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"all"}, function(data){
			if(data === "notlogin"){
                Store.setStore('isLoggin', false);
                if(typeof callback === 'function') callback();
            }else if(typeof data === 'object'){
				if(data.length){ 
                    Store.setStore("devicesAll", data);
                    Store.setStore('isLoggin', true);
                    if(typeof callback === 'function') callback();
				}
			}
        });
	}

    handleSubmit = (e) => {
        
        e.preventDefault();
        const this0 = this;
        var id = this.state.identificacion;
        var pass = this.state.clave;
        // Sacamos los datos de token para las notificaciones de Rafa
        var pushtk_tk = window.localStorage.getItem('pushtk_tk') || "";
        var pushtk_id = window.localStorage.getItem('pushtk_id') || "";
        var pushtk_ostype = window.localStorage.getItem('pushtk_ostype') || "";
        var pushtk_osver = window.localStorage.getItem('pushtk_osver') || "";
        if(this.state.identificacion && this.state.clave){
            var rajax = jQuery.post("https://app.hontrack.com/apiphp/login.php", {id:id, pass:pass, pushtk_tk:pushtk_tk, pushtk_id:pushtk_id, pushtk_ostype:pushtk_ostype, pushtk_osver:pushtk_osver},function(data) {
                if(data === "accepted"){
                    this0.llenarLocalStorageconDatosSesion();
                    this0.cargarDispositivos(function(){
                        //this0.props.history.push('/dashboard');
                        window.location = '#/dashboard';
                        Store.setStore('isLoggin', true);
                    });
                }
                if(data === "usernotfound"){
                    let aceptarboton = function(){ this0.setState({tempop:'', clave: ''}); }
                    let alert = (
                    <Alert closefn={aceptarboton}>
                        <center>
                            <img className="bigicon" src={Icons['error']} alt=""/>
                            <h3>Credenciales incorrectas, intentalo de nuevo.</h3>
                        </center>
                    </Alert>
                    );
                    this0.setState({tempop:alert});
                }
            });
            this0.setState({tempop:<Loading close={()=>{this0.setState({tempop:''})}} request={rajax} />});
        }else{
            let aceptarboton = function(){ this0.setState({tempop:''}); }
            let alert = (
            <Alert closefn={aceptarboton}>
                <center>
                    <img className="bigicon" src={Icons['error']} alt=""/>
                    <h3>Por favor llena correctamente los campos.</h3>
                </center>
            </Alert>
            );
            this0.setState({tempop:alert});
        }
    }

    onChangeCheckSave=(state)=>{
        // Si activamos el boton guardar
        if(state){
            window.localStorage.setItem('hontrackSavePassClient', "1");
            this.setState({checkActiveState: state});
        }else{
            window.localStorage.removeItem('hontrackSavePassClient');
            window.localStorage.removeItem('hontrackSavePassClient_usrid');
            window.localStorage.removeItem('hontrackSavePassClient_userpass');
            this.setState({identificacion:"", clave: "", checkActiveState: state});
            // console.log('borrar datos');
        }
    }

    llenarLocalStorageconDatosSesion=()=>{
        window.localStorage.setItem('hontrackSavePassClient_usrid', this.state.identificacion);
        window.localStorage.setItem('hontrackSavePassClient_userpass', this.state.clave);
    }

    changeidentificacion = (e) => {
        this.setState({identificacion:e.target.value});
    }

    changeClave = (e) => {
        this.setState({clave:e.target.value});
    }

    onBlurPass=()=>{
        this.setState({readOnlyPass:true});
    }

    onFocusPass=()=>{
        this.setState({readOnlyPass:false});
    }

    componentWillUnmount(){
        Store.unregListener("ui", 'login');
        Store.unregListener("devicesAll", 'login');
        Store.unregListener("isLoggin", 'login');
    }
	
    render() {
        return (
            <div className="app">
                {this.state.tempop}
                <div className="pddapp">
                    <Link className="pddapp-logo" to="/"><img alt="" src={Icons['logo']} /></Link>
                    <div className="form">
                        {/* <div className="form-title">Ingresar</div> */}
                        <div className="form-g">
                            <label>Usuario</label>
                            <input autoCapitalize="false" onChange={this.changeidentificacion} value={this.state.identificacion} type="email" />
                        </div>
                        <div className="form-g">
                            <label>Contraseña</label>
                            {/* <input onFocus={this.onFocusPass} onBlur={this.onBlurPass} onChange={this.changeClave} readOnly={this.state.readOnlyPass} value={this.state.clave} type="password" /> */}
                            <input onFocus={this.onFocusPass} onChange={this.changeClave} value={this.state.clave} type="password" />
                        </div>
                        <div className="form-g">
                            <Check onChange={this.onChangeCheckSave} label="Recordar datos de acceso" active={this.state.checkActiveState} />
                        </div>
                        <div onClick={this.handleSubmit} className="form-button">Ingresar</div>
                        <div className="form-g">
                            <Link className="form-linkfull" to="/recovery">Recuperar cuenta</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
