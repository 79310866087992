import React, { Component } from 'react';
import Store from '../store';
import jQuery from 'jquery';
import Offline from '../views/offline.jsx';

class Env extends Component {
    constructor(){
        super();
        this.state = {
            ajax1: '',
            estaCargado: false,
            estaOnline: true
        }
    }

    onResizeWindow = () => {
        var ui = "desktop";
        if(window.innerWidth < 1100) ui = "tablet";
        if(window.innerWidth < 720) ui = "mobile";
        Store.setStore("ui", ui);
    };
    
    componentDidMount(){
        var this0 = this;

        this.onResizeWindow();
        window.addEventListener('resize', this.onResizeWindow);
        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

        // Cada cantidad de segundo actualiza la informacion de los dispositivos
        // console.log(this.props.location.pathname);
        this0.cargarDispositivos(function(){
            setInterval(function(){
                this0.cargarDispositivos();
            }, 5000);
        });
        
    }

    updateOnlineStatus=()=>{
        if(navigator.onLine){ // Hay internet
            this.setState({estaOnline: true});
            window.location.reload();
        }else{ // No hay internet
            this.setState({estaOnline: false});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeWindow);
        if(this.state.ajax1) this.state.ajax1.abort();
    }

    cargarDispositivos = (callback) =>{
        // Hacer peticion para recuperar todos los dispositivos gps ligados al usuario
        // esto tambien esta en login.jsx si se cambia cambiar alla tambien
        var this0 = this;
		var ajax1 = jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"all"}, function(data){
			if(data === "notlogin"){
                Store.setStore('isLoggin', false);
                if(typeof callback === 'function') callback();
            }else if(typeof data === 'object'){
				if(data.length > 0){ 
                    if(typeof callback === 'function') callback();
                    Store.setStore("devicesAll", data);
                    Store.setStore('isLoggin', true);
                    this0.setState({estaCargado: true});
				}
			}
        });
        this.setState({ajax1: ajax1});
	}

    render() {
        var children = (this.state.estaOnline) ? this.props.children : <Offline /> ;
		return (
			<div className="env">
                {children}
			</div>
		);
	}
}

export default Env;