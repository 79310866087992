import React, { Component } from 'react';
import Icons from '../controls/svgicons';

class Preload extends Component {
    constructor(){
        super();
        this.state = {
            imgs:'',
            arraysrc: Icons
        };
    }

    componentDidMount(){
        let listado = [];
        for (var key in Icons) {
            if (Icons.hasOwnProperty(key)) {
                var src = Icons[key];
                listado.push(
                    <img width="24" src={src} alt="" key={src} />
                );
            }
        }
        this.setState({imgs:listado});
    }

	render() {
		return (
			<div className="preloadassets">
				{this.state.imgs}
			</div>
		);
	}
}

export default Preload;
