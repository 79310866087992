import React, { Component } from 'react';
import {Map, GoogleApiWrapper} from 'google-maps-react';

class MapContainer extends Component {
  constructor(){
    super();
    this.state = {
      mapType: 'roadmap'
    }
  }

  componentDidMount(){
    
  }

  onReadyMap=(mapProps, map)=>{
    var styledMapType = new this.props.google.maps.StyledMapType(
      [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#263c3f"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6b9a76"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#38414e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#212a37"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9ca5b3"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#1f2835"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#f3d19c"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2f3948"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#515c6d"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        }
      ],{name: 'Dark Map'});
    map.mapTypes.set('noche', styledMapType);
    if(typeof this.props.onReady === 'function') this.props.onReady(mapProps, map, this.props.google);
  }

  onDragendMap=(mapProps, map)=>{
    // console.log(mapProps);
    if(typeof this.props.onDragend === 'function') this.props.onDragend(mapProps, map);
  }

  onZoomChangedMap=(mapProps, map)=>{
    // console.log(mapProps);
    if(typeof this.props.onZoomChangedMap === 'function') this.props.onZoomChangedMap(mapProps, map);
  }

  onBoundsChangedMap=(mapProps, map)=>{
    // console.log(mapProps);
    // console.log(map.getZoom());
    if(typeof this.props.onBoundsChangedMap === 'function') this.props.onBoundsChangedMap(mapProps, map);
  }

  onClickMap=(mapProps)=>{
    // console.log(mapProps);
    if(typeof this.props.onClick === 'function') this.props.onClick(mapProps);
  }

  render() {
    return (
      <Map google={this.props.google}
        onReady={this.onReadyMap}
        onClick={this.onClickMap}
        onDragend={this.onDragendMap}
        onZoom_changed={this.onZoomChangedMap}
        onBounds_changed={this.onBoundsChangedMap}
        zoom={this.props.zoom||7} 
        initialCenter={{lat: 14.789107,lng: -86.5937667}} 
        center={this.props.center}
        zoomControl={false}
        styles={this.props.styles}
        streetViewControl={false}
        fullscreenControl={false}
        mapType="roadmap"
        mapTypeControl={false}
      >
        {this.props.children}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD5XD7NrmB8oU4Pp50UayRJdgJQGx7YPHA',
  language: 'es'
})(MapContainer);