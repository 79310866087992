import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import { Link, useParams } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import 'rc-slider/assets/index.css';
import Store from '../store';
import Menuseldev from '../controls/menuseldev.jsx';
import Menu from '../controls/menu.jsx';
import moment from 'moment';

class HistoryMap extends Component {

    constructor(props) {
        super();
        this.state = {
            deviceId: props.params.id,
            DevCod: props.params.id2,
            devDataActual: false,

            map: false,
            mapProps: false,
            google: false,
            
            centerMap: {lat: 0, lng: 0},
            allPins: false,
            actualPin: '',
            zoom: 14,

            minutosDeStops: 5,
            velocidadDeStops: 60,

            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},

            calendarOpen: true,
            calSelectedDay: new Date(),
            data: [],
            actualLat: 0,
            actualLng: 0,
            actualIndex: 0,
            maxIndex: 0,
            marker: [],
            barraPlayS: {'display': 'none'},
            popWindow: '',
            cargandoDatos: false,
            playing: false,
            playVelocity: 1,
            handleSlider: '',
            actualIndexForma: false,
            titleDevice: 'Cargando...',
            DevNum: '',
            ajax1: '',
            myTimeOut: false,

            kmsRecorridos: '',
            kmsPromedio: '',
            kmsVelicidadMax: '',
            inicioActividad: '',
            tiempoDetenido: '',
            tiempoMoviendose: '',
            finActividad: '',
            vecesDetenido: 0,

            stopsPins: [],
            allStops: [],
            allPinSpeed: []
        }
    }

    UNSAFE_componentWillMount(){
      Store.regListener("ui", 'historymap',this.onuiStoreChange);
      Store.regListener("devicesAll", 'historymap',this.onDevicesAllStoreChange);
      Store.regListener("isLoggin", 'historymap',this.onisLogginChange);
    }

    componentDidMount() {
      this.onuiStoreChange();
		  this.onDevicesAllStoreChange();
		  this.onisLogginChange();


          this.setState({Rep_fecha11: moment().subtract('10', 'days').format('YYYY-MM-DD')});
          this.setState({Rep_fecha12: moment().format('YYYY-MM-DD')});
          this.setState({Rep_fecha21: moment().subtract('10', 'days').format('YYYY-MM-DD')});
          this.setState({Rep_fecha22: moment().format('YYYY-MM-DD')});
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({
            deviceId: props.params.id, 
            DevCod: props.params.id2,
            calendarOpen: true,
            data: [],
            allStops: [],
            allPinSpeed: [],
            allPins: []
        }, ()=>{
            this.onDevicesAllStoreChange();
        });
    }

    onuiStoreChange=()=>{
		this.setState({ui: Store.getStore('ui')});
    }
    
    onDevicesAllStoreChange=()=>{
        if(Store.getStore("devicesAll")){
            var data = Store.getStore("devicesAll");
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const element = data[key];
                    if(element.DevCod === this.state.DevCod){
                        if(JSON.stringify(element) !== JSON.stringify(this.state.devDataActual)){
                            this.setState({devDataActual: element});
                        }
                    }
                }
            }
        }
    }
    
    onisLogginChange=()=>{
        if(!Store.getStore('isLoggin')){
            window.location = '/';
        }
	}

    componentWillUnmount(){
        if(this.state.ajax1) this.state.ajax1.abort();
        Store.unregListener("ui", 'historymap');
        Store.unregListener("devicesAll", 'historymap');
        Store.unregListener("isLoggin", 'historymap');
    }

  

    onChangeInputEventos_fecha1 = (e)=>{
        console.log(e.target.value);
        this.setState({Rep_fecha11: e.target.value});
    }
    
    onChangeInputEventos_fecha2 = (e)=>{
        console.log(e.target.value);
        this.setState({Rep_fecha12: e.target.value});
    }

    onChangeInputResumen_fecha1 = (e)=>{
        console.log(e.target.value);
        this.setState({Rep_fecha21: e.target.value});
    }
    
    onChangeInputResumen_fecha2 = (e)=>{
        console.log(e.target.value);
        this.setState({Rep_fecha22: e.target.value});
    }

    descargarReporteEventos = (e)=>{
        let fechaInicio = this.state.Rep_fecha11;
        let fechaFinal = this.state.Rep_fecha12;
        let ccodigodev = this.state.deviceId;
        let urlregenerada = 'https://app.hontrack.com/apiphp/admin/deviceEventsPDF.php?DevCod='+ccodigodev+'&FchIni='+fechaInicio+'&FchFin='+fechaFinal;
        window.open(urlregenerada);
        // console.log(this.state);
    }



    descargarReporteResumen = (e)=>{
        let fechaInicio = this.state.Rep_fecha21;
        let fechaFinal = this.state.Rep_fecha22;
        let ccodigodev = this.state.deviceId;
        let urlregenerada = 'https://app.hontrack.com/apiphp/admin/deviceResumenPDF.php?DevCod='+ccodigodev+'&FchIni='+fechaInicio+'&FchFin='+fechaFinal;
        window.open(urlregenerada);
        // console.log(this.state);
    }



    // descargarReporteEventos = (e)=>{
    //     console.log(e.target.value);
    // }
 
 

 



    render() {
        return (
            <div className="app">
                {this.state.popWindow}
                {this.state.popWindowFixSliderActive && this.configurarPinsStops()}
                {this.state.popWindowFixSlider2Active && this.configurarPinsVelocidad()}
                <div className="appbar">
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-title">Historial de ubicaciones</Link>
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-b1"><img alt="" src={Icons['back']}/></Link>
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-b2"><img alt="" src={Icons['logo']}/></Link>
                    {/* <div onClick={this.toggleMostrarCalendario} title="Seleccionar fecha" className="appbar-b3"><img alt="" src={Icons['cale2']} /></div> */}
                </div>
                <div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon full"}>
                    {this.state.ui === "desktop" && <Menu id1={this.state.DevCod} />}
					          {this.state.ui === "desktop" && <Menuseldev linktipo="t3" />}
                    <div className='appcon-pdd'>
                    <div className="appcon-header">
                      <h1>Reportes</h1>
                      <span>Descargar reportes</span>
                    </div>

                      <div className="bloquedescargarreporte">
                        <div className="bloquedescargarreporte-g">
                          <h3>Reporte de Eventos</h3>
                          <label htmlFor="">Seleccione las fechas: </label>
                          <input value={this.state.Rep_fecha11} onChange={this.onChangeInputEventos_fecha1} type={'date'} />&nbsp;
                          <input value={this.state.Rep_fecha12} onChange={this.onChangeInputEventos_fecha2} type={'date'} />&nbsp;
                          <div onClick={this.descargarReporteEventos} className="formnuevoboton">Descargar</div>
                        </div>
                        <div className="bloquedescargarreporte-g">
                          <h3>Reporte de Seguimiento</h3>
                          <label htmlFor="">Seleccione las fechas: </label>
                          <input value={this.state.Rep_fecha21} onChange={this.onChangeInputResumen_fecha1} type={'date'} />&nbsp;
                          <input value={this.state.Rep_fecha21} onChange={this.onChangeInputResumen_fecha2} type={'date'} />&nbsp;
                          <div onClick={this.descargarReporteResumen} className="formnuevoboton">Descargar</div>
                        </div>
                        
                      </div>

                    </div>
                </div>
            </div>
        );
    }
}



let HistoryMapHook = () => {
	let params = useParams();
	return <HistoryMap params = {params}  />;
} 

export default HistoryMapHook;



