import React, { Component } from 'react';

class Box extends Component{
    constructor(){
		super();
		this.state = {
			style:{}
		};
	}

    close = () => {
        if(typeof this.props.closefn === "function") this.props.closefn();
    }

    clickwindow = (e) => {
        e.stopPropagation();
    }

    componentDidMount(){
        if(this.props.sinpdd){
            this.setState({style:{padding: '0'}});
        }
    }

    render(){
        return(
            <div onClick={this.close} className="cont-popwindow">
                <div onClick={this.clickwindow} className="popwindow">
                    <div style={this.state.style} className="popwindow-cont">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Box;