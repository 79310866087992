import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../controls/svgicons';
import Menu from '../controls/menu.jsx';
import Menuseldev from '../controls/menuseldev.jsx';
import GoogleMap from '../controls/mapcontainer.jsx';
import Store from '../store';
import {Marker} from 'google-maps-react';
import Box from '../controls/box.jsx';
import Alert from '../controls/alert.jsx';
import Confirm from '../controls/confirm.jsx';
import jQuery from 'jquery';


class Vehicle extends Component {
	constructor(props){
		super();
	
		this.state = {
			id: props.params.id,
			mapReady: false,
			google: false,
			map: false,
			mapProps: false,
			center: {lat: 0, lng: 0},
			readyMapState: false,
			zoom: 15,
			keepCenter: false,
			marker: false,
			markerPosition: {lat: 0, lng: 0},
			devDataAll: [],
			devDataActual: false,
			allOtherPins: [],
			DevBlk: false,
			popWindow: ''
		};
	}
	
	componentWillMount(){
		console.log('componentWillMount');
		Store.regListener("ui", 'vehiclev2', this.onuiStoreChange);
		Store.regListener("devicesAll", 'vehiclev2', this.onDevicesAllStoreChange);
		Store.regListener("isLoggin", 'vehiclev2', this.onisLogginChange);
		this.setState({id: this.props.params.id});
	}

	componentDidMount(){
		console.log('componentDidMount');
		this.onuiStoreChange();
		this.onDevicesAllStoreChange();
		this.onisLogginChange();
	}

	onuiStoreChange=()=>{
		console.log('onuiStoreChange');
		this.setState({ui: Store.getStore('ui')});
	}
    
	onDevicesAllStoreChange=()=>{
		console.log('onDevicesAllStoreChange');
		this.setState({devDataAll: Store.getStore('devicesAll')},()=>{
			this.updateMarker();
		})
	}

	componentWillReceiveProps(props){
		console.log('componentWillReceiveProps');
		if(this.state.devDataAll.find((element)=>{ return element.DevCod === props.params.id })){
			this.setState({
				id: props.params.id,
				popWindow: ''
			},()=>{
				this.updateMarker();
				this.generarOtrosPines();
			});
		}else{
			this.setState({popWindow:<Alert closefn={()=>{this.setState({popWindow: ''})}}>No se encontro el dispositivo</Alert>});
		}
	}
	
	updateMarker=()=>{
		console.log('updateMarker');
		// Actualiza el Marker si la posicion cambio
		var devDataList = this.state.devDataAll;
		for (const key in devDataList) {
			if (devDataList.hasOwnProperty(key)) {
				const element = devDataList[key];
				if(this.state.id === element.DevCod){
					this.setState({devDataActual: element, DevBlk: element.DevBlk});
					let markerPositionNew = {lat: element.DevLstLat, lng: element.DevLstLog};
					// console.log('markerPositionNew');
					// console.log(markerPositionNew);
					// console.log('this.state.markerPosition');
					// console.log(this.state.markerPosition);
					if(JSON.stringify(markerPositionNew) !== JSON.stringify(this.state.markerPosition) && this.state.mapReady){
						this.setState({markerPosition: markerPositionNew, center: markerPositionNew},()=>{
							console.log('Pin actualizado');
							let marker = <Marker 
								icon={{
									url: Icons['car'],
									anchor: new this.state.google.maps.Point(30,60),
									scaledSize: new this.state.google.maps.Size(60,60)
								}}
								onClick={()=>{this.clickLiveMarker(this.state.markerPosition)}}
								zIndex={999}
								name={'Current location'} position={this.state.markerPosition} 
								/>;
							this.setState({marker: marker});
						});
					}else{
						console.log('Pin no actualizado');
					}
				}
			}
		}
	}
	
	clickLiveMarker=(data)=>{
		console.log(data);
		
	}

	onisLogginChange=()=>{
		console.log('onisLogginChange');
		if(!Store.getStore('isLoggin')){
			window.location = '#/';
		}
	}

	onReadyMap=(mapProps, map, google)=>{
		console.log('onReadyMap');
		if(localStorage.getItem("h_MapTypeId")) map.setMapTypeId(localStorage.getItem("h_MapTypeId")); // Pone el tema que esta en el storage
		this.setState({mapReady: true, map: map, mapProps: mapProps, google:google}, ()=>{
			this.updateMarker();
			this.generarOtrosPines();
		});
	}

	onBoundsChangedMap=(mapProps, map)=>{
		// Cada vez que el mapa se actualice tambien actualizo el nivel de zoom
		// Para evitar bugs con los controles manuales de zoom
		this.setState({zoom:map.getZoom()}); 
	}

	cambiarDispositivo=()=>{
		if(this.state.ui === "desktop") return false;
		if(!this.state.devDataAll.length) return false;

		var popWindow = "";
		var closefn = ()=>{ this.setState({popWindow: ''}); }

		popWindow = 
			<Box sinpdd={true} closefn={closefn}>
				<Menuseldev linktipo="vehicle" className="adj"></Menuseldev>
			</Box>;
		this.setState({popWindow:popWindow});
	}

	generarOtrosPines=()=>{
		// evitamos errores solo corriendo el script
		// si el mapa esta ready aunque lo declaremos
		// antes de que el mapa este cargado

		if(!this.state.mapReady) return false; // Si el mapa no esta listo no hace nada
		if(this.state.showingInfoWindow) return false; // Si esta activa una ventana evita actualizaciones
		// if(this.state.allOtherPins.length) return false; // Evita mas actualizaciones

		let allOtherPins = [];
		// console.log(this.state.devDataAll);
		const data = this.state.devDataAll;
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if(this.state.id !== data[key].DevCod){ // No pinta el seleccionado
					const element = data[key];
					// console.log(element);
					var bloqueado = "Desbloqueado";
					if(element.DevBlk === "ON") bloqueado = "Bloqueado";
					var encendido = "Apagado";
					if(element.DevACC === 1) encendido = "Encendido";
					var velocidad = "Detenido";
					if(element.DevLstSpd > 4) velocidad = element.DevLstSpd+"km/h";
					let nameAsInfoWindow = 
						<div className="globalmap-infowindow">
							<h3>{element.DevDesc}</h3>
							<div>{bloqueado}</div>
							<div>{encendido}</div>
							<div>{velocidad}</div>
							<a href={"#/vehicle/"+element.DevCod}>Ver en vivo</a>
						</div>;
					allOtherPins.push(
						<Marker 
							key={Math.random().toString(36).substr(2, 9)}
							onClick={()=>this.onOtherPinClick(element.DevCod)}
							icon={{
								url: Icons['cargris'],
								anchor: new this.state.google.maps.Point(20,40),
								scaledSize: new this.state.google.maps.Size(40,40)
							}}
							title={element.DevDesc}
							name={nameAsInfoWindow} 
							position={{lat: element.DevLstLat, lng: element.DevLstLog}} 
						/>
					);
				}
			}
		}
		this.setState({allOtherPins: allOtherPins});
	}

	onOtherPinClick=(id)=>{
		window.location = "#/vehicle/"+id;
	}

	bloquearVehiculo=()=>{
		var deviceid = this.state.devDataActual.DevCod;
		var devnum = this.state.devDataActual.DevNum;
		if(this.state.bloqueoHaciendoPeticion) return false;

		var peticionBloquearVehiculo = ()=>{
			this.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_on", deviceid:deviceid, devnum:devnum}, (data)=>{
				this.setState({bloqueoHaciendoPeticion: false});
				if(data === 'active'){
					console.log('ya esta bloqueado');
				}
				if(data === 'inserted'){
					this.setState({
						DevBlk: 'PDTON'
					});
				}
				if(data === 'error'){
					console.log('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					console.log('error no creditos');
				}
			});
		}
	
		let cerrarventana=()=>{ this.setState({popWindow:''}) };
		let yesfn=()=>{ peticionBloquearVehiculo(); cerrarventana(); }
		let popWindow = (
		<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
			<h2>¿Deseas bloquear?</h2>
			<div style={{padding: '0 2em'}}>
				<span>Este vehículo no se podrá encender hasta que sea desbloqueado de nuevo, si está en movimiento se apagara el motor a una velocidad prudente, esto puede tomar unos minutos. <br/><br/>¿deseas continuar?</span>
			</div>
		</Confirm>
		);
		this.setState({popWindow:popWindow});
	}

	desbloquearVehiculo=(e)=>{
		e.preventDefault();
		var deviceid = this.state.devDataActual.DevCod;
		var devnum = this.state.devDataActual.DevNum;
		if(this.state.bloqueoHaciendoPeticion) return false;

		var peticionDesbloquearVehiculo = ()=>{
			this.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_off", deviceid:deviceid, devnum:devnum, force:'OK'}, (data)=>{
				this.setState({bloqueoHaciendoPeticion: false});
				if(data === 'inactive'){
					console.log('ya esta desactivado');
				}
				if(data === 'inserted'){
					this.setState({
						DevBlk: 'PDTOFF'
					});
				}
				if(data === 'error'){
					console.log('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					console.log('error no creditos');
				}
			});
		}

		let cerrarventana=()=>{ this.setState({popWindow:''}) };
		let yesfn=()=>{ peticionDesbloquearVehiculo(); cerrarventana(); }
		let popWindow = (
		<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
			<h2>¿Desbloqueo manual?</h2>
			<div style={{padding: '0 2em'}}>
				<span>Esta acción desbloqueara el vehículo sin importar si está bloqueado o desbloqueado, recomendamos usar esta opción solo si por alguna razón no puedes usar el bloqueo normal.<br/><br/>¿deseas continuar?</span>
			</div>
		</Confirm>
		);
		this.setState({popWindow:popWindow});

	}

	toggleBloqueo=()=>{
		var this0 = this;
		var deviceid = this0.state.devDataActual.DevCod;
		var devnum = this0.state.devDataActual.DevNum;
		if(this.state.bloqueoHaciendoPeticion) return false;

		var bloquearVehiculo = function(){
			this0.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_on", deviceid:deviceid, devnum:devnum}, function(data){
				this0.setState({bloqueoHaciendoPeticion: false});
				if(data === 'active'){
					console.log('ya esta bloqueado');
				}
				if(data === 'inserted'){
					this0.setState({
						DevBlk: 'PDTON'
					});
				}
				if(data === 'error'){
					console.log('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					console.log('error no creditos');
				}
			});
		}
	
		var desbloquearVehiculo = function(){
			this0.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_off", deviceid:deviceid, devnum:devnum}, function(data){
				this0.setState({bloqueoHaciendoPeticion: false});
				if(data === 'inactive'){
					console.log('ya esta desactivado');
				}
				if(data === 'inserted'){
					this0.setState({
						DevBlk: 'PDTOFF'
					});
				}
				if(data === 'error'){
					console.log('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					console.log('error no creditos');
				}
			});
		}

		if(this.state.DevBlk === 'OFF'){ // Si esta apagado al hacer click hacemos esto
			let styleConteText = {
				padding: '0 2em'
			}
			let cerrarventana=()=>{ this.setState({popWindow:''}) };
			let yesfn=()=>{ bloquearVehiculo(); cerrarventana(); }
			let popWindow = (
			<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
				<h2>¿Deseas bloquear?</h2>
				<div style={styleConteText}>
					<span>Este vehículo no se podrá encender hasta que sea desbloqueado de nuevo, si está en movimiento se apagara el motor a una velocidad prudente.</span>
				</div>
			</Confirm>
			);
			this0.setState({popWindow:popWindow});
		}
		if(this.state.DevBlk === 'ON'){ // Si esta encendido al hacer click hacemos esto
			
			let styleConteText = {
				padding: '0 2em'
			}
			let cerrarventana=()=>{ this.setState({popWindow:''}) };
			let yesfn=()=>{ desbloquearVehiculo(); cerrarventana(); }
			let popWindow = (
			<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
				<h2>¿Desbloquear?</h2>
				<div style={styleConteText}>
					<span>Estas a punto de desbloquear tu vehículo, <br/>¿deseas continuar?</span>
				</div>
			</Confirm>
			);
			this0.setState({popWindow:popWindow});
		}
	}

	btZonaSegura=()=>{
		let cerrarventana=()=>{ this.setState({popWindow:''}) };
		let yesfn=()=>{ cerrarventana(); }
		let popWindow = (
		<Confirm yesfn={yesfn} yesfntext="OK" nofn={cerrarventana} nofntext="Cerrar" >
			<h2>En desarrollo</h2>
			<div>
				<span>Esta característica está en desarrollo por favor espere...</span>
			</div>
		</Confirm>
		);
		this.setState({popWindow:popWindow});
	}

	mostrarOpcionesVehiculo=()=>{
		if(!this.state.devDataActual) return false;
		var id = this.state.devDataActual.DevCod;
		var imei = this.state.devDataActual.DevIMEI;

		var closefn = ()=>{ this.setState({popWindow: ''}); }
		var popWindow = "";
		popWindow = 
			<Box closefn={closefn}>
					<div className="basicmenu">
						<div onClick={this.bloquearVehiculo} className="basicmenu-ele">Bloquear</div>
						<div onClick={this.desbloquearVehiculo} className="basicmenu-ele">Desbloquear</div>
						<Link className="basicmenu-ele" to={'/historymap/'+imei+"/"+id}>Historial</Link>
						<Link className="basicmenu-ele" to={'/alerts/'+id}>Configurar notificaciones</Link>
					</div>
			</Box>
		this.setState({popWindow:popWindow});
	}

	irHistorial=()=>{
		let id1 = this.state.devDataActual.DevCod;
		let id2 = this.state.devDataActual.DevIMEI;
		window.location = '#/historymap/'+id2+'/'+id1;
	}

	componentWillUnmount(){
		Store.unregListener("ui", 'vehiclev2');
		Store.unregListener("devicesAll", 'vehiclev2');
		Store.unregListener("isLoggin", 'vehiclev2');
	}

	render() {

		var TitleMap=()=>{
			// console.log(this.props.context.state.devDataActual);
			var velocidad = this.state.devDataActual.DevLstSpd;
			var bloqueado = this.state.devDataActual.DevBlk;
			var encendido = this.state.devDataActual.DevACC;
			var bloqueadoTxt = 'desbloqueado';
			if(bloqueado === "OFF") bloqueadoTxt = <span className="verde">desbloqueado</span>;
			if(bloqueado === "PDTON") bloqueadoTxt = "bloqueando...";
			if(bloqueado === "PDTOFF") bloqueadoTxt = "desbloqueando...";
			if(bloqueado === "ON") bloqueadoTxt = <span className="rojo">bloqueado</span>;
			var velocidadTxt = "Detenido";
			if(velocidad > 4) velocidadTxt = velocidad+"km/h";
			return (
				<div className="mapvehiclev2-headermap">
					<div className="mapvehiclev2-headermap-con">
						<h1>{this.state.devDataActual.DevDesc}</h1>
						<center>{velocidadTxt}, {bloqueadoTxt}</center>
						<div className={"mapvehiclev2-headermap-con-state "+(encendido?"active":"")}></div>
					</div>
				</div>
			);
		} 
		
		let btbloqueoClass = '';
		let btbloqueoIMG = '';
		let btbloqueoStateClass = '';
		if(this.state.DevBlk === "PDTON" || this.state.DevBlk === "PDTOFF" || this.state.bloqueoHaciendoPeticion) btbloqueoClass = ' loading';
		if(this.state.DevBlk === "ON") btbloqueoIMG = Icons['apagado1'];
		if(this.state.DevBlk === "OFF"){ btbloqueoIMG = Icons['apagado0']; btbloqueoStateClass = ' active'; }
		if(this.state.DevBlk === "PDTON") btbloqueoIMG = Icons['apagado1'];
		if(this.state.DevBlk === "PDTOFF"){ btbloqueoIMG = Icons['apagado0']; btbloqueoStateClass = ' active'; }

		return (
			<div className="app">
				{this.state.popWindow}
				<div className="appbar">
					<Link to={"/dashboard/"+this.state.devDataActual.DevCod} className="appbar-title">Ubicación actual</Link>
					<Link to={"/dashboard/"+this.state.devDataActual.DevCod} className="appbar-b1"><img alt="" src={Icons['back']} /></Link>
					<Link to={"/dashboard/"+this.state.devDataActual.DevCod} className="appbar-b2"><img alt="" src={Icons['logo']} /></Link>
					{this.state.devDataAll.length > 1 && <div onClick={this.cambiarDispositivo} title="Cambiar de vehículo" className="appbar-b3"><img alt="" src={Icons['filtro']} /></div> }
				</div>
				<div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon full"}>
					{this.state.ui === "desktop" && <Menu id1={this.state.devDataActual.DevCod} />}
					{this.state.ui === "desktop" && <Menuseldev linktipo="vehicle" />}
					<div className="appcon-mapa mapvehiclev2">
						
						<TitleMap />
						<GoogleMap zoom={this.state.zoom} center={this.state.center} onReady={this.onReadyMap} onBoundsChangedMap={this.onBoundsChangedMap}>
							{this.state.allOtherPins}
							{this.state.marker}
						</GoogleMap>
						<ControllersMap context={this} />
						{/* <div onClick={this.mostrarOpcionesVehiculo} title="Opciones del vehículo" className="mapvehiclev2-btopciones"><img src={Icons['more']} alt=""/></div> */}
						
						{this.state.devDataActual.DevLck === "0" && 
							<div onContextMenu={this.desbloquearVehiculo} onClick={this.toggleBloqueo} title="Bloquear o desbloquear vehículo" className={"mapvehiclev2-btbloqueo"+btbloqueoClass}>
								<div className={"mapvehiclev2-btbloqueo-state"+btbloqueoStateClass}></div>
								<img src={btbloqueoIMG} alt=""/>
							</div>
						}
						<div onClick={this.irHistorial} className="mapvehiclev2-bthistorial">
							<img src={Icons['cale']} alt=""/>
						</div>
						{/* <div onClick={this.btZonaSegura} className="mapvehiclev2-btsecurezone">
							<img src={Icons['secure']} alt=""/>
						</div> */}
						
					</div>
				</div>
				
			</div>
		);
	}
}

class ControllersMap extends Component {
	cambiarLayer=()=>{
		// Cambia la vista del terreno
		if(this.props.context.state.map.mapTypeId === 'hybrid'){
			this.props.context.state.map.setMapTypeId('roadmap');
			localStorage.setItem("h_MapTypeId", "roadmap");
		}else if(this.props.context.state.map.mapTypeId === 'roadmap'){
			this.props.context.state.map.setMapTypeId('noche');
			localStorage.setItem("h_MapTypeId", "noche");
		}else{
			this.props.context.state.map.setMapTypeId('hybrid');
			localStorage.setItem("h_MapTypeId", "hybrid");
		}
	}

	aumentarZoom=()=>{
		if(this.props.context.state.zoom < 19){
			this.props.context.setState({zoom: this.props.context.state.zoom+1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
			
		}
	}
	
	reducirZoom=()=>{
		if(this.props.context.state.zoom > 2){
			this.props.context.setState({zoom: this.props.context.state.zoom-1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
		}
	}

	centrarMapa=()=>{
		// Al parecer si center ya tiene las coordenadas aunque el mapa este en otro lugar no acutaliza center
		// Entonces lo que hare sera modificar coordenadas justo antes de reasignarlas al mismo valor eso arreglara todo creo
		this.props.context.setState({center: {lat: 14, lng: -87}},()=>{
			this.props.context.setState({center: this.props.context.state.markerPosition});
		});
		// console.log(this.props.context.state.markerPosition);
		// console.log(this.props.context.state.map);
		// console.log(this.props.context.state.mapProps);
	}

	render(){
		return(
			<div className="genmapctrlsv1">
				<div onClick={this.reducirZoom} title="Alerjar" className="genmapctrlsv1-btn"><img src={Icons['zoom2']} alt=""/></div>
				<div onClick={this.centrarMapa} title="Centrar mapa" className="genmapctrlsv1-btn"><img src={Icons['center']} alt=""/></div>
				<div onClick={this.cambiarLayer} title="Cambiar modo de terreno" className="genmapctrlsv1-btn"><img src={Icons['layers']} alt=""/></div>
				<div onClick={this.aumentarZoom} title="Acercar" className="genmapctrlsv1-btn"><img src={Icons['zoom1']} alt=""/></div>
			</div>
		)
	}
}

let VehicleHook = () => {
	let params = useParams();
	return <Vehicle params = {params}  />;
} 

export default VehicleHook;
