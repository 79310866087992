import logo from '../images/logo.svg';
import logob from '../images/logob.svg';
import pin from '../images/pin.svg';
import pinmap from '../images/pinmap.svg';
import pinmap2 from '../images/pinmap2.svg';
import menu from '../images/menu.svg';
import search from '../images/search.svg';
import close from '../images/close.svg';
import exit from '../images/exit.svg';
import password from '../images/password.svg';
import account from '../images/account.svg';
import account2 from '../images/account2.svg';
import balance from '../images/balance.svg';
import help from '../images/help.svg';
import back from '../images/back.svg';
import more from '../images/more.svg';
import location from '../images/location.svg';
import locationta from '../images/locationta.svg';
import locations from '../images/locations.svg';
import edit from '../images/edit.svg';
import add from '../images/add.svg';
import view from '../images/view.svg';
import error from '../images/error.svg';
import refresh from '../images/refresh.svg';
import map from '../images/map.svg';
import settings from '../images/settings.svg';
import speed from '../images/speed.svg';
import records from '../images/records.svg';
import done from '../images/done.svg';
import done2 from '../images/done2.svg';
import coin from '../images/coin.svg';
import coin2 from '../images/coin2.svg';
import noti0 from '../images/noti0.svg';
import noti1 from '../images/noti1.svg';
import apagado0 from '../images/apagado0.svg';
import apagado1 from '../images/apagado1.svg';
import asisvial from '../images/asisvial.svg';
import car from '../images/car.svg';
import cargris from '../images/cargris.svg';
import carb from '../images/carb.svg';
import history from '../images/history.svg';
import play from '../images/play.svg';
import stop from '../images/stop.svg';
import pause from '../images/pause.svg';
import refe from '../images/refe.svg';
import changebills from '../images/changebills.svg';
import cale from '../images/cale.svg';
import block from '../images/block.svg';
import filtro from '../images/filtro.svg';
import cale2 from '../images/cale2.svg';
import signal from '../images/signal.svg';
import gas from '../images/gas.svg';
import speed2 from '../images/speed2.svg';
import battery from '../images/battery.svg';
import enchu0 from '../images/enchu0.svg';
import enchu1 from '../images/enchu1.svg';
import stats from '../images/stats.svg';
import dashboard from '../images/dashboard.svg';
import nonet from '../images/nonet.svg';
import zoom1 from '../images/zoom1.svg';
import zoom2 from '../images/zoom2.svg';
import layers from '../images/layers.svg';
import center from '../images/center.svg';
import linesh from '../images/linesh.svg';
import flag from '../images/flag.svg';
import flagpin from '../images/flagpin.svg';
import speedb from '../images/speedb.svg';
import secure from '../images/secure.svg';
import reports from '../images/reports.svg';
import zonas from '../images/zonas.svg';
import download from '../images/download.svg';
import download2 from '../images/download2.svg';

const svglist = {
    'logo':logo,
    'logob':logob,
    'menu':menu,
    'search':search,
    'close':close,
    'exit':exit,
    'password':password,
    'account':account,
    'account2':account2,
    'balance':balance,
    'help':help,
    'back':back,
    'more':more,
    'pin':pin,
    'pinmap':pinmap,
    'pinmap2':pinmap2,
    'location':location,
    'locationta':locationta,
    'locations':locations,
    'edit':edit,
    'add':add,
    'settings':settings,
    'view':view,
    'error':error,
    'refresh':refresh,
    'map':map,
    'done':done,
    'done2':done2,
    'noti0':noti0,
    'noti1':noti1,
    'apagado0':apagado0,
    'apagado1':apagado1,
    'coin':coin,
    'coin2':coin2,
    'records':records,
    'speed':speed,
    'speed2':speed2,
    'asisvial':asisvial,
    'car':car,
    'cargris':cargris,
    'carb':carb,
    'history':history,
    'play':play,
    'stop':stop,
    'pause':pause,
    'refe':refe,
    'changebills':changebills,
    'block':block,
    'cale':cale,
    'cale2':cale2,
    'gas':gas,
    'signal':signal,
    'stats':stats,
    'battery':battery,
    'filtro':filtro,
    'enchu1':enchu1,
    'enchu0':enchu0,
    'dashboard':dashboard,
    'zoom1':zoom1,
    'zoom2':zoom2,
    'layers':layers,
    'center':center,
    'linesh':linesh,
    'flag':flag,
    'flagpin':flagpin,
    'speedb':speedb,
    'secure':secure,
    'reports':reports,
    'zonas':zonas,
    'nonet':nonet,
    'download':download,
    'download2':download2,
};


export default svglist;