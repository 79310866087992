import React, { Component } from 'react';

class Confirm extends Component{
    constructor(){
        super();
        this.state = {
        }
    }

    clickwindow = (e) => {
        e.stopPropagation();
    }

    yesfn=()=>{
        if(this.props.yesfn) this.props.yesfn();
        // if(this.props.nofn) this.props.nofn();
    }

    render(){
        return(
            <div onClick={this.props.nofn || ""} className="cont-popwindow">
                <div onClick={this.clickwindow} className="popwindow">
                    <div className="popwindow-cont">
                        {this.props.children}
                    </div>
                    <div className="popwindow-botbar dividido">
                        <button onClick={this.props.nofn || ""}>{this.props.nofntext || "Cancelar"}</button>
                        <button onClick={this.yesfn}>{this.props.yesfntext || "Aceptar"}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Confirm;