import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import Menu from '../controls/menu.jsx';
import Store from '../store';
import GoogleMap from '../controls/mapcontainer.jsx';
import {Marker, InfoWindow} from 'google-maps-react';
import Menuseldev from '../controls/menuseldev.jsx';
import Box from '../controls/box.jsx';


class GlobalMap extends Component {
	constructor(){
		super();
	
		this.state = {
			google: false,
			map: false,
			mapReady: false,
			mapProps: false,
			zoom: 7,
			centerMap: {lat: 14.789107,lng: -86.5937667},

			showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},

			dataMenu: [],
			allDevicesData: false,
			allPins: []
		};
	}

	componentWillMount(){
		Store.regListener("ui", 'globalmap',this.onuiStoreChange);
		Store.regListener("devicesAll", 'globalmap',this.onDevicesAllStoreChange);
        Store.regListener("isLoggin", 'globalmap',this.onisLogginChange);
	}

	componentDidMount(){
		this.onuiStoreChange();
		this.onDevicesAllStoreChange();
		this.onisLogginChange();
	}

	infoWindow=()=>{
		// Si no hay todavia ningun lugar seleccionado
		// no hacemos nada
		if(!this.state.selectedPlace) return false;
		return (
			<InfoWindow
				marker={this.state.activeMarker}
				visible={this.state.showingInfoWindow}
				onClose={()=>{this.setState({showingInfoWindow:false})}}
				>
					<div>{this.state.selectedPlace.name}</div>
			</InfoWindow>
		);
	}

	onuiStoreChange=()=>{
		this.setState({ui: Store.getStore('ui')});
    }
    
    onDevicesAllStoreChange=()=>{
		// Cuando la informacion de los dispositivos es actualizada
		this.setState({allDevicesData: Store.getStore('devicesAll')},()=>{
			console.log(this.state.allDevicesData);
			//this.generarMapaPins();
			this.generarMenuData();
		});
    }
    
    onisLogginChange=()=>{
        if(!Store.getStore('isLoggin')){
            this.props.history.push('/');
        }
	}

	generarMenuData=()=>{
		if(!this.state.allDevicesData) return false;
		var data = this.state.allDevicesData;
		var newData = [];
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const element = data[key];
				newData[key] = element;
				newData[key].onClick=()=>{
					this.setState({
						popWindow: "",
						centerMap: {lat: element.DevLstLat, lng: element.DevLstLog}, 
						zoom: 14
					});
				};
			}
		}
		this.setState({dataMenu:newData});
	}

	generarMapaPins=()=>{
		// evitamos errores solo corriendo el script
		// si el mapa esta ready aunque lo declaremos
		// antes de que el mapa este cargado

		if(!this.state.mapReady) return false; // Si el mapa no esta listo no hace nada
		if(this.state.showingInfoWindow) return false; // Si esta activa una ventana evita actualizaciones

		let allPins = [];
		const data = this.state.allDevicesData;
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const element = data[key];
				var bloqueado = "Desbloqueado";
				if(element.DevBlk === "ON") bloqueado = "Bloqueado";
				var encendido = "Apagado";
				if(element.DevACC === 1) encendido = "Encendido";
				var velocidad = "Detenido";
				if(element.DevLstSpd > 4) velocidad = element.DevLstSpd+"km/h";
				let nameAsInfoWindow = 
					<div className="globalmap-infowindow">
						<h3>{element.DevDesc}</h3>
						<div>{bloqueado}</div>
						<div>{encendido}</div>
						<div>{velocidad}</div>
						<a href={"#/vehicle/"+element.DevCod}>Ver en vivo</a>
					</div>;
				allPins.push(
					<Marker 
						key={Math.random().toString(36).substr(2, 9)}
						onClick={this.onMarkerClick}
						icon={{
							url: Icons['car'],
							anchor: new this.state.google.maps.Point(20,40),
							scaledSize: new this.state.google.maps.Size(40,40)
						}} 
						name={nameAsInfoWindow} 
						position={{lat: element.DevLstLat, lng: element.DevLstLog}} 
					/>
				);
			}
		}
		this.setState({allPins: allPins});
	}

	onReadyMap=(mapProps, map, google)=>{
		console.log('onReadyMap');
		if(localStorage.getItem("h_MapTypeId")) map.setMapTypeId(localStorage.getItem("h_MapTypeId")); // Pone el tema que esta en el storage
		this.setState({mapReady: true, map: map, mapProps: mapProps, google:google}, ()=>{
            //this.generarMapaPins();
        });
	}

	onBoundsChangedMap=(mapProps, map)=>{
		// Cada vez que el mapa se actualice tambien actualizo el nivel de zoom
		// Para evitar bugs con los controles manuales de zoom
		this.setState({zoom:map.getZoom()}); 
	}

	onMarkerClick = (props, marker, e) =>{
        this.setState({
          selectedPlace: props,
          activeMarker: marker,
          showingInfoWindow: true
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
            showingInfoWindow: false,
            activeMarker: null
            })
        }
    }

	openClickMenu = () => {
		if(this.state.ui === "desktop") return false;
		this.setState({menu:<Menu closeClickMenu={()=>{this.setState({menu:""});}} />});
	}

	cambiarDispositivo=()=>{
		if(this.state.ui === "desktop") return false;

		var popWindow = "";
		var closefn = ()=>{ this.setState({popWindow: ''}); }

		popWindow = 
			<Box sinpdd={true} closefn={closefn}>
				<Menuseldev data={this.state.dataMenu} className="adj"></Menuseldev>
			</Box>;
		this.setState({popWindow:popWindow});
	}

	componentWillUnmount(){
        Store.unregListener("ui", 'globalmap');
        Store.unregListener("devicesAll", 'globalmap');
        Store.unregListener("isLoggin", 'globalmap');
	}
	
	render() {
		return (
			<div className="app">
				{this.state.popWindow}
				{this.state.menu}
				<div className="appbar">
					<div className="appbar-title">Ubicación actual</div>
					<div onClick={this.openClickMenu} className="appbar-b1"><img alt="" src={Icons['menu']} /></div>
					<div className="appbar-b2"><img alt="" src={Icons['logo']} /></div>
					<div onClick={this.cambiarDispositivo} title="Cambiar de vehículo" className="appbar-b3"><img alt="" src={Icons['filtro']} /></div>
				</div>
				<div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon full"}>
					{this.state.ui === "desktop" && <Menu />}
					{this.state.ui === "desktop" && <Menuseldev data={this.state.dataMenu} />}
					<div className="appcon-mapa">
						
						<GoogleMap onClick={this.onMapClicked} zoom={this.state.zoom} center={this.state.centerMap} onReady={this.onReadyMap} onBoundsChangedMap={this.onBoundsChangedMap}>
                            {this.state.allPins}
							{this.infoWindow()}
						</GoogleMap>
						{this.state.allDevicesData && <ControllersMap context={this} />}
						{/* {this.state.allDevicesData && <BarraSelectVehicleGlobalMap context={this} />} */}
					</div>
				</div>
				
			</div>
		);
	}
}

class ControllersMap extends Component {
	cambiarLayer=()=>{
		// Cambia la vista del terreno
		if(this.props.context.state.map.mapTypeId === 'hybrid'){
			this.props.context.state.map.setMapTypeId('roadmap');
			localStorage.setItem("h_MapTypeId", "roadmap");
		}else if(this.props.context.state.map.mapTypeId === 'roadmap'){
			this.props.context.state.map.setMapTypeId('noche');
			localStorage.setItem("h_MapTypeId", "noche");
		}else{
			this.props.context.state.map.setMapTypeId('hybrid');
			localStorage.setItem("h_MapTypeId", "hybrid");
		}
	}

	aumentarZoom=()=>{
		if(this.props.context.state.zoom < 19){
			this.props.context.setState({zoom: this.props.context.state.zoom+1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
			
		}
	}
	
	reducirZoom=()=>{
		if(this.props.context.state.zoom > 2){
			this.props.context.setState({zoom: this.props.context.state.zoom-1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
		}
	}

	centrarMapa=()=>{
		// Al parecer si center ya tiene las coordenadas aunque el mapa este en otro lugar no acutaliza center
		// Entonces lo que hare sera modificar coordenadas justo antes de reasignarlas al mismo valor eso arreglara todo creo
		this.props.context.setState({centerMap: {lat: 14, lng: -87}},()=>{
			this.props.context.setState({centerMap: {lat: this.props.context.state.actualLat, lng: this.props.context.state.actualLng}});
		});
	}

	render(){
		return(
			<div className="genmapctrlsv1 vertical">
				<div onClick={this.reducirZoom} title="Alerjar" className="genmapctrlsv1-btn"><img src={Icons['zoom2']} alt=""/></div>
				{/* <div onClick={this.centrarMapa} title="Centrar mapa" className="genmapctrlsv1-btn"><img src={Icons['center']} alt=""/></div> */}
				<div onClick={this.cambiarLayer} title="Cambiar modo de terreno" className="genmapctrlsv1-btn"><img src={Icons['layers']} alt=""/></div>
				<div onClick={this.aumentarZoom} title="Acercar" className="genmapctrlsv1-btn"><img src={Icons['zoom1']} alt=""/></div>
			</div>
		)
	}
}

export default GlobalMap;
