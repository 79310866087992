import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../controls/svgicons';
import Menu from '../controls/menu.jsx';
import jQuery from 'jquery';
import Confirm from '../controls/confirm.jsx';
import Box from '../controls/box.jsx';
import Alert from '../controls/alert.jsx';
import Menuseldev from '../controls/menuseldev.jsx';
import Devdetails from '../controls/devdetails';
import NoLogin from '../views/nologin.jsx';
import Store from '../store';

class Dashboard extends Component {
	constructor(){
		super();
		this.state = {
			id: '',
			popWindow: '',
			isLoggin: false,
			devDataList: [],
			devDataActual: [],
			alertaState: false,
			alertaCargando: false,
			bloqueoState: false,
			bloqueoHaciendoPeticion: false,
			errorNoData: false,
			DevBlk: false,
			ajax1: '',
			ajax2: '',
			ajax3: '',
			menu: '',
			ui: ''
		};
	}
	
	componentDidMount(){
		
		Store.regListener("ui", 'dashboardv2',this.onuiStoreChange);
		Store.regListener("devicesAll", 'dashboardv2',this.onDevicesAllStoreChange);
		Store.regListener("isLoggin", 'dashboardv2',this.onisLogginChange);
		this.onisLogginChange();
		this.setState({ui: Store.getStore('ui')});
		this.setState({id:this.props.params.id},()=>{
			if(Store.getStore('devicesAll').length) this.onDevicesAllStoreChange(); // Intocables, ni lo pienses.
		});
	}

	onuiStoreChange=()=>{
		this.setState({ui: Store.getStore('ui')});
    }
    
    onDevicesAllStoreChange=()=>{
		// Cuando la informacion de los dispositivos es actualizada
		var id = this.state.id;
		var devicesAll = Store.getStore('devicesAll');
		if(devicesAll.find(function(element){return element.DevCod === id;})){
			for (const key in devicesAll) {
				if (devicesAll.hasOwnProperty(key)) {
					const element = devicesAll[key];
					if(element.DevCod === id){
						this.setState({devDataActual: element, devDataList: devicesAll, DevBlk: element.DevBlk});
					}
				}
			}
			if(!this.state.id)  window.location = '#/dashboard/'+devicesAll[0].DevCod; // Intocables, ni lo pienses.
		}else{
			// Si no se encuentra data en el id que le pasamos por parametro
			 window.location = '#/dashboard/'+devicesAll[0].DevCod;
		}
    }
    
    onisLogginChange=()=>{
		this.setState({isLoggin: Store.getStore('isLoggin')});
        if(!Store.getStore('isLoggin')){
             window.location = '#/';
        }
	}

	UNSAFE_componentWillReceiveProps(props){
		// console.log(props.match.params.id);
		this.setState({
			id: props.params.id,
			popWindow: '',
			devDataList: [],
			devDataActual: [],
			alertaState: false,
			alertaCargando: false,
			bloqueoState: false,
			bloqueoHaciendoPeticion: false,
			errorNoData: false,
			DevBlk: false,
			ajax1: '',
			ajax2: '',
			ajax3: '',
			menu: '',
			ui: ''
		},()=>{
			this.setState({ui: Store.getStore('ui')});
			this.onDevicesAllStoreChange();
			
		});
	}

	cargarDispositivos = (id) =>{
		// Recuperamos todos los dispositivos del Store
		this.setState({devDataList: Store.getStore('devicesAll')}, function(){
			let datafull = this.state.devDataList;
			let dataSele = datafull[0];
			this.seleccionarDev(dataSele.DevCod || id);
		});
	}

	dispositivoFallandoManual=()=>{
		var this0 = this;
		let closefn = function(){this0.setState({popWindow:''});}
		let popWindow = (
			<Alert closefn={closefn}>
				<p>El dispositivo GPS esta presentado problemas, por favor llamar a revisión.</p>
			</Alert>
		);
		this0.setState({popWindow:popWindow});
	}

	clickVerMapa = () =>{
		if(!this.state.devDataActual) return false;
		var id = this.state.devDataActual.DevCod;
		 window.location = '#/vehicle/'+id;
	}

	clickVerHistorial = () =>{
		if(!this.state.devDataActual) return false;
		var id = this.state.devDataActual.DevCod;
		var imei = this.state.devDataActual.DevIMEI;
		 window.location = '#/historymap/'+imei+"/"+id;
	}

	openClickMenu = () => {
		if(this.state.ui === "desktop") return false;
		this.setState({menu:<Menu closeClickMenu={()=>{this.setState({menu:""});}} />});
	}

	componentWillUnmount(){
		clearTimeout(this.state.timeout1);
		if(this.state.ajax1) this.state.ajax1.abort();
		if(this.state.ajax2) this.state.ajax2.abort();
		if(this.state.ajax3) this.state.ajax3.abort();
		Store.unregListener("ui", 'dashboardv2');
        Store.unregListener("devicesAll", 'dashboardv2');
        Store.unregListener("isLoggin", 'dashboardv2');
	}

	irAlertas=()=>{
		 window.location = '#/alerts/'+this.state.id;
	}

	toggleBloqueo=()=>{
		var this0 = this;
		var deviceid = this0.state.devDataActual.DevCod;
		var devnum = this0.state.devDataActual.DevNum;
		if(this.state.bloqueoHaciendoPeticion) return false;

		var bloquearVehiculo = function(){
			this0.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_on", deviceid:deviceid, devnum:devnum}, function(data){
				this0.setState({bloqueoHaciendoPeticion: false});
				if(data === 'active'){
					alert('ya esta bloqueado');
				}
				if(data === 'inserted'){
					this0.setState({
						DevBlk: 'PDTON'
					});
				}
				if(data === 'error'){
					alert('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					alert('error no creditos');
				}
			});
		}
	
		var desbloquearVehiculo = function(){
			this0.setState({bloqueoHaciendoPeticion: true});
			jQuery.post("https://app.hontrack.com/apiphp/devices.php", {md:"sdr_off", deviceid:deviceid, devnum:devnum}, function(data){
				this0.setState({bloqueoHaciendoPeticion: false});
				if(data === 'inactive'){
					alert('ya esta desactivado');
				}
				if(data === 'inserted'){
					this0.setState({
						DevBlk: 'PDTOFF'
					});
				}
				if(data === 'error'){
					alert('error activando alerta');
				}
				if(data === 'error_NOT_CREDITS'){
					alert('error no creditos');
				}
			});
		}

		if(this.state.DevBlk === 'OFF'){ // Si esta apagado al hacer click hacemos esto
			let styleConteText = {
				padding: '0 2em'
			}
			let cerrarventana=()=>{ this.setState({popWindow:''}) };
			let yesfn=()=>{ bloquearVehiculo(); cerrarventana(); }
			let popWindow = (
			<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
				<h2>¿Deseas bloquear?</h2>
				<div style={styleConteText}>
					<span>Este vehículo no se podrá encender hasta que sea desbloqueado de nuevo, si está en movimiento se apagara el motor a una velocidad prudente.</span>
				</div>
			</Confirm>
			);
			this0.setState({popWindow:popWindow});
		}
		if(this.state.DevBlk === 'ON'){ // Si esta encendido al hacer click hacemos esto
			
			let styleConteText = {
				padding: '0 2em'
			}
			let cerrarventana=()=>{ this.setState({popWindow:''}) };
			let yesfn=()=>{ desbloquearVehiculo(); cerrarventana(); }
			let popWindow = (
			<Confirm yesfn={yesfn} yesfntext="si" nofn={cerrarventana} nofntext="no" >
				<h2>¿Desbloquear?</h2>
				<div style={styleConteText}>
					<span>Estas a punto de desbloquear tu vehículo, <br/>¿deseas continuar?</span>
				</div>
			</Confirm>
			);
			this0.setState({popWindow:popWindow});
		}
	}

	cambiarDispositivo=()=>{
		if(this.state.ui === "desktop") return false;
		if(!this.state.devDataList.length) return false;

		var popWindow = "";
		var closefn = ()=>{ this.setState({popWindow: ''}); }

		popWindow = 
			<Box sinpdd={true} closefn={closefn}>
				<Menuseldev className="adj"></Menuseldev>
			</Box>;
		this.setState({popWindow:popWindow});
	}

	render() {

		var bloqueoExtraCssName = "";
		// var bloqueBoleanState = false;
		var titleBloqueo = "Cargando...";
		var descBloqueo = "Por favor espere...";
		
		if(this.state.DevBlk === "ON"){
			// bloqueBoleanState = true;
			titleBloqueo = "Desbloquear";
			descBloqueo = "Tu vehículo esta bloqueado, toca para desbloquear.";
		}else if(this.state.DevBlk === "PDTON"){
			// bloqueBoleanState = true;
			titleBloqueo = "Bloqueando...";
			descBloqueo = "Estamos bloqueando el vehículo, espere...";
			bloqueoExtraCssName = "working";
		}else if(this.state.DevBlk === "OFF"){
			// bloqueBoleanState = false;
			titleBloqueo = "Bloquear";
			descBloqueo = "Tu vehículo esta desbloqueado, toca para bloquear.";
		}else if(this.state.DevBlk === "PDTOFF"){
			// bloqueBoleanState = false;
			bloqueoExtraCssName = "working";
			titleBloqueo = "Desbloqueando...";
			descBloqueo = "Estamos desbloqueando el vehículo, espere...";
		}

		if(this.state.bloqueoHaciendoPeticion){
			bloqueoExtraCssName = "working";
			titleBloqueo = "Solicitando";
			descBloqueo = "Estamos haciendo la solicitud al servidor.";
		}

		var titleMapa = "Cargando...";
		var titleHistorial = "Cargando...";
		var descMapa = "Por favor espere...";
		var descHistorial = "Por favor espere...";
		if(this.state.devDataActual){
			titleMapa = "Ubicación actual";
			titleHistorial = "Historial";
			descMapa = "Ver ubicación en vivo.";
			descHistorial = "Ver ubicaciones pasadas.";
		}

		var devDesc = "CARGANDO...";
		if(this.state.devDataActual.DevDesc){
			let cnehsmhf3 = "";
			if(this.state.devDataActual.DevLstSpd > 2){ cnehsmhf3 = "En movimiento a " + this.state.devDataActual.DevLstSpd+"km/h"; } else{ cnehsmhf3 = "Vehículo detenido"; }
			devDesc = cnehsmhf3;
		}

		var mostrarBotonBloqueo = true;
		if(this.state.devDataActual.DevMod === "TK102"){ mostrarBotonBloqueo = false; }
		if(this.state.devDataActual.DevLck === "1"){ mostrarBotonBloqueo = false; }
		console.log(this.state.devDataActual);

		var txtEngine = "Motor apagado";
		var textEngineState = "";
		if(this.state.devDataActual.DevACC === 1){
			txtEngine = "Motor encendido";
			textEngineState = "active";
		}

		var mostrarEstadosDev = false;
        if(this.state.devDataActual.DevMod === "TK315"){ mostrarEstadosDev = true; }
        if(this.state.devDataActual.DevMod === "GPS110"){ mostrarEstadosDev = true; }

		if(this.state.isLoggin)	return (
			<div className="app">
				{this.state.popWindow}
				{this.state.menu}
				<div className="appbar">
					<div className="appbar-title">Panel del vehículo</div>
					<div onClick={this.openClickMenu} className="appbar-b1"><img alt="" src={Icons['menu']} /></div>
					<div className="appbar-b2"><img alt="" className="icons_logo" src={Icons['logo']} /></div>
					{ this.state.devDataList.length > 1 && <div onClick={this.cambiarDispositivo} title="Cambiar de vehículo" className="appbar-b3"><img alt="" src={Icons['filtro']} /></div> }
				</div>
				<div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon"}>
					{this.state.ui === "desktop" && <Menu id1={this.state.id} />}
					{this.state.ui === "desktop" && <Menuseldev linktipo="t1" data={this.state.devDataList} />}

					<div className="appcon-pdd">
						<div className="appcon-header">
							<h1>Vehículo actual</h1>
						</div>
						<div className="devSelect">
							<div onClick={this.clickVerMapa} className="devSelect-b1">
								
								<div className="devSelect-b1-img">
									{mostrarEstadosDev && 
									<div className="devSelect-b1-engine">
										<span className={"devSelect-b1-engine-icon "+textEngineState}>
											<span className="devSelect-b1-engine-icon-text"></span>
										</span>
										
									</div>
									}
									{mostrarEstadosDev || <img alt="" src={Icons['pin']} /> }
								</div>
								<div className="devSelect-b1-name">
									<div>{this.state.devDataActual.DevDesc}</div>
									<span>{txtEngine +" | "+ devDesc}</span>
								</div>
							</div>
							<div className="devSelect-b2">
								<ul>
									<li>
										<div onClick={this.clickVerMapa} className="iconlinkdev">
											<div className="iconlinkdev-svg"><img src={Icons['map']} alt=""/></div>
											<div className="iconlinkdev-text">
												<b>{titleMapa}</b>
												<span>{descMapa}</span>
											</div>
										</div>
									</li>
									<li>
										<div onClick={this.clickVerHistorial} className="iconlinkdev">
											<div className="iconlinkdev-svg"><img src={Icons['cale']} alt=""/></div>
											<div className="iconlinkdev-text">
												<b>{titleHistorial}</b>
												<span>{descHistorial}</span>
											</div>
										</div>
									</li>
									{mostrarBotonBloqueo && 
									<li>
										<div onClick={this.toggleBloqueo} className={"iconlinkdev "+bloqueoExtraCssName}>
											<div className="iconlinkdev-svg"><img src={Icons['apagado1']} alt=""/></div>
											<div className="iconlinkdev-text">
												<b>{titleBloqueo}</b>
												<span>{descBloqueo}</span>
												{/* <input type="checkbox" checked={bloqueBoleanState} className="switch" id="switch-2"/> */}
											</div>
										</div>
									</li>
									}
									<li>
										<Link to={'/alerts/'+this.state.id} className={"iconlinkdev"}>
											<div className="iconlinkdev-svg"><img src={Icons['noti0']} alt=""/></div>
											<div className="iconlinkdev-text">
												<b>Alertas</b>
												<span>Configurar alertas para este vehículo</span>
												{/* <input type="checkbox" checked={this.state.alertaState} className="switch" id="switch-1"/> */}
											</div>
										</Link>
									</li>
									<li>
										<Link to={'/reports/'+this.state.id} className={"iconlinkdev"}>
											<div className="iconlinkdev-svg"><img src={Icons['download2']} alt=""/></div>
											<div className="iconlinkdev-text">
												<b>Reportes</b>
												<span>Descargar reportes</span>
												{/* <input type="checkbox" checked={this.state.alertaState} className="switch" id="switch-1"/> */}
											</div>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="condevdetalis">
							<Devdetails data={this.state.devDataActual} />
						</div>
					</div>
				</div>
			</div>
		);
		if(!this.state.isLoggin) return (
			<NoLogin></NoLogin>
		);
		
	}
}

let DashboardHook = () => {
	let params = useParams();
	return <Dashboard params = {params}  />;
} 

export default DashboardHook;
