import React, { Component } from 'react';
import Icons from '../controls/svgicons';

class devdetails extends Component {
	constructor(){
		super();
		this.state = {
            data: []
		};
	}

	UNSAFE_componentWillReceiveProps(props){
        this.setState({props:props});
    }

	render() {

        var mostrarBarra = false;
        if(this.props.data.DevMod === "TK315"){ mostrarBarra = true; }
        if(this.props.data.DevMod === "GPS110"){ mostrarBarra = true; }

		return (
			<div className="devdetails">
                {mostrarBarra && 
                    <div className="devdetails-list">
                        <div className="devdetails-ele">
                        <b>Dispositivo</b>
                        </div>
                        <div className="devdetails-ele" title="Intensidad de la señal">
                            <img src={Icons['signal']} alt=""/>
                            <b>{this.state.props.data.DevSignal}%</b>
                        </div>
                        <div className="devdetails-ele" title="Nivel de bateria de dispositivo GPS">
                            {this.state.props.data.DevCharge === 1 ? <img src={Icons['enchu1']} alt=""/> : <img src={Icons['enchu0']} alt=""/>}
                            {this.state.props.data.DevCharge === 1 || <img className="blinker" src={Icons['battery']} alt=""/>}
                            <b>{(this.state.props.data.DevBatLvl>100)?100:this.state.props.data.DevBatLvl}%</b>
                        </div>
                    </div>
                }
			</div>
		);
	}
}

export default devdetails;
