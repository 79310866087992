import React, { Component } from 'react';

class InfolistEle extends Component {
    constructor(){
        super();
        this.state = {
            label: "",
            title: "",
            img: "",
            onClick: "",
            extraclass: ""
        }
    }

    componentWillReceiveProps(props){
        // console.log("componentWillReceiveProps");
    }

    componentDidMount(){
        
        var title = (this.props.title)? <h1>{this.props.title}</h1>:"";
        var label = (this.props.label)? <span>{this.props.label}</span>:"";
        var img = (this.props.ico)? <img src={this.props.ico} alt="" />:"";
        var onClick = this.props.onClick;
        var extraclass = (typeof this.props.onClick === "function")?"clickleable":"";

        this.setState({
            title:title,
            label:label,
            img:img,
            extraclass:extraclass,
            onClick: onClick
        });
        
    }
    

	render() {
		return (
			<div onClick={this.state.onClick} className={"infolist-ele "+ this.state.extraclass}>
                {this.state.label}                        
                {this.state.title}
                {this.state.img}
            </div>
		);
	}
}

export default InfolistEle;
